import { FeatureFlagService, ILaunchDarklyFeatureFlags, LaunchDarklyFeatureFlags } from "../../../feature-flags";
import { IStep, IWorkflow, IWorkflowDesign } from "@visoryplatform/workflow-core";
import { ITimeline, IWorkflowDesignType, WorkflowCreationOptions } from "@visoryplatform/threads";
import { Observable, combineLatest } from "rxjs";
import { map, shareReplay, switchMap, take } from "rxjs/operators";

import { IPaginated } from "@visoryplatform/datastore-types";
import { Injectable } from "@angular/core";
import { WorkflowApiService } from "./workflow-api.service";

export interface IProviderWorkflow {
    provider: string;
    workflow: IWorkflow;
}

export interface IThreadWorkflowGroup {
    threads: ITimeline[];
    provider: string;
    workflow: IWorkflow;
    active?: boolean;
}

@Injectable({
    providedIn: "root",
})
export class WorkflowService {
    designCache: Record<string, Observable<IWorkflowDesign>> = {};

    constructor(private workflowApi: WorkflowApiService, private featureFlagService: FeatureFlagService) {}

    listWorkflowDesigns(next?: string, limit?: number): Observable<IPaginated<IWorkflowDesign>> {
        return this.workflowApi.listWorkflowDesigns(next, limit);
    }

    listAccountWorkflowDesigns(
        accountId: string,
        creationOption?: WorkflowCreationOptions,
    ): Observable<IPaginated<IWorkflowDesign>> {
        return this.workflowApi.listAccountWorkflowDesigns(accountId, creationOption);
    }

    // Depreate feature flag/condition in ED-8028 as we are moving to support filtering feature flagged designs
    listDesignTypes(): Observable<IWorkflowDesignType[]> {
        const designTypes$ = this.workflowApi.listDesignTypes();
        const featureFlags$ = this.featureFlagService.getFlags();
        const enabledFeature$ = this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableCreateWorkflowModal);

        return enabledFeature$.pipe(
            take(1),
            switchMap((enabled) =>
                enabled ? this.filterDesignsByFeatureFlag(designTypes$, featureFlags$) : designTypes$,
            ),
        );
    }

    getDesign(designId: string): Observable<IWorkflowDesign> {
        if (!this.designCache[designId]) {
            const design$ = this.workflowApi.getDesign(designId).pipe(shareReplay(1));
            this.designCache[designId] = design$;
        }

        return this.designCache[designId];
    }

    getCurrentStep(workflow: IWorkflow): Readonly<IStep> {
        return workflow?.steps?.[workflow.currentStepId];
    }

    private filterDesignsByFeatureFlag(
        designTypes$: Observable<IWorkflowDesignType[]>,
        featureFlags$: Observable<ILaunchDarklyFeatureFlags>,
    ): Observable<IWorkflowDesignType[]> {
        return combineLatest([designTypes$, featureFlags$]).pipe(
            take(1),
            map(([designTypes, featureFlags]) => this.applyFeatureFlagFilterToDesignTypes(designTypes, featureFlags)),
        );
    }

    private applyFeatureFlagFilterToDesignTypes(
        designTypes: IWorkflowDesignType[],
        flags: ILaunchDarklyFeatureFlags,
    ): IWorkflowDesignType[] {
        return designTypes.filter((design) => {
            if (!design?.requiredFeatureFlagStates) {
                return true;
            }

            const designsFeatureFlags = Object.entries(design.requiredFeatureFlagStates);
            return designsFeatureFlags.every(([flag, state]) => flags[flag] === state);
        });
    }
}
