import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Role, WorkflowDueDateService, WorkflowStepType } from "@visoryplatform/threads";
import { ENVIRONMENT } from "src/app/injection-token";
import {
    IWorkflow,
    IStep,
    IWorkflowAction,
    SystemStepId,
    AssigneeExtensionHelpers,
    WorkTimeExtensionHelpers,
} from "@visoryplatform/workflow-core";
import { EnvironmentSpecificConfig } from "../../../environment/environment.common";
import { IWorkflowStepUI } from "../../../threads-ui/interfaces/IWorkflowStepUI";
import { WorkflowTextConstants } from "../../constants/workflow-text.constants";
import { LaunchDarklyFeatureFlags } from "../../../feature-flags";
import { FeatureFlagService } from "../../../feature-flags";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PermissionService } from "../../../threads-ui/services/permissions.service";

@Component({
    selector: "workflow-step-entry",
    templateUrl: "./workflow-step-entry.component.html",
    styleUrls: ["./workflow-step-entry.component.scss"],
})
export class WorkflowStepEntryComponent implements OnInit, OnChanges {
    @Input() entry: IWorkflowStepUI;
    @Input() workflow: IWorkflow;
    @Input() role: Role;
    @Input() accountLabel: string;
    @Input() index: number;
    @Input() isLastMilestone: boolean;

    @Output() performAction = new EventEmitter<IWorkflowAction>();

    /** TODOS in html */

    readonly stepTypes = WorkflowStepType;
    readonly theme = this.environment.theme;
    readonly SystemStepId = SystemStepId;
    readonly FEATURE_FLAGS = LaunchDarklyFeatureFlags;

    workTimeSubject = new BehaviorSubject<number>(undefined);

    hasWorkTimeViewPermission$: Observable<boolean>;
    hasWorkTimeFeatureEnabled$: Observable<boolean>;
    workTimeValue = this.workTimeSubject.asObservable();
    workTime$: Observable<number>;

    hasDueDates: boolean;
    roles = Role;
    selectedStep: IStep;
    stateChanged = false;
    text = WorkflowTextConstants;
    timeZone: string;
    assignees: string[];

    constructor(
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private permissionService: PermissionService,
        private featureFlagService: FeatureFlagService,
    ) {}

    ngOnInit(): void {
        this.hasWorkTimeViewPermission$ = this.permissionService.checkPermissions(this.role, "ViewWorkTime");
        this.hasWorkTimeFeatureEnabled$ = this.featureFlagService.getFlag(
            LaunchDarklyFeatureFlags.EnableWorkTimeOnWorkflowStepper,
        );
        this.workTime$ = combineLatest([
            this.hasWorkTimeViewPermission$,
            this.hasWorkTimeFeatureEnabled$,
            this.workTimeValue,
        ]).pipe(
            map(
                ([hasWorkTimeViewPermission, hasWorkTimeFeatureEnabled, workTime]) =>
                    hasWorkTimeViewPermission && hasWorkTimeFeatureEnabled && workTime,
            ),
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { entry } = changes;

        if (entry) {
            this.hasDueDates = WorkflowDueDateService.isSla(this.entry?.step);
            this.assignees = AssigneeExtensionHelpers.getAssigneeData(this.entry?.step?.extensions)?.assignees || [];
            const workTime = WorkTimeExtensionHelpers.getMultipliedWorkTime(this.entry?.step?.extensions);
            this.workTimeSubject.next(workTime);
        }
    }

    selectStepAt(action: IWorkflowAction): void {
        this.performAction.emit(action);
    }
}
