<loader-icon
    *ngIf="loader.counter"
    color="light"
    [showBackground]="true"
></loader-icon>

<sidebar-modal-layout
    header="New request"
    (close)="close()"
>
    <stepper-layout
        *ngIf="customerNewRequestStepperVisible$ | async"
        [linear]="true"
        endStepLabel="Submit"
        (completed)="create()"
        (cancel)="close()"
        [disabled]="!!loader.counter"
        [disabledNext]="!form.valid"
        #stepper
    >
        <stepper-step
            label=""
            [completed]="true"
            [hideNavigationButtons]="true"
            [showBreadcrumbs]="false"
        >
            <div class="customer-request-inner-container">
                <div class="customer-request-body">
                    <div class="customer-request-subtitle">
                        Create a new request if you need assistance with a <b>new task or issue</b> that's
                        <b>not already part of your active workflows.</b>
                    </div>

                    <div class="customer-request-options-title">Choose your preferred option:</div>

                    <div
                        class="customer-request-options"
                        *ngIf="messageButtonVisible$ | async"
                    >
                        <div class="customer-request-option">
                            <div class="customer-request-option-content">
                                <div class="customer-request-option-icon">
                                    <i class="las la-paper-plane"></i>
                                </div>
                                <div>
                                    <div class="customer-request-option-title">Create request</div>
                                    <div class="customer-request-option-description">
                                        Send a platform message instantly.
                                    </div>
                                </div>
                            </div>

                            <button
                                class="fx-btn fx-btn--primary customer-request-option-button"
                                (click)="goNextToCreate()"
                            >
                                <i class="las la-paper-plane"></i>
                                Create now
                            </button>
                        </div>
                    </div>

                    <div
                        class="customer-request-options"
                        *ngIf="phoneButtonVisible$ | async"
                    >
                        <div class="customer-request-option">
                            <div class="customer-request-option-content">
                                <div class="customer-request-option-icon">
                                    <i class="las la-phone"></i>
                                </div>
                                <div>
                                    <div class="customer-request-option-title">Phone</div>
                                    <div class="customer-request-option-description">
                                        Chat to our team 9am to 5pm AEDST.
                                    </div>
                                </div>
                            </div>

                            <div class="customer-request-option-link">
                                Call <a href="tel:+1800847679">1800 VISORY</a>
                            </div>
                        </div>
                    </div>

                    <div
                        class="customer-request-options"
                        *ngIf="emailButtonVisible$ | async"
                    >
                        <div class="customer-request-option">
                            <div class="customer-request-option-content">
                                <div class="customer-request-option-icon">
                                    <i class="las la-envelope"></i>
                                </div>
                                <div>
                                    <div class="customer-request-option-title">Email</div>
                                    <div class="customer-request-option-description">Send us an email.</div>
                                </div>
                            </div>

                            <div class="customer-request-option-link">
                                Email <a href="mailto: support@visory.com.au">support@visory.com.au</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </stepper-step>

        <stepper-step
            label="Tell us about your request"
            [completed]="true"
            [showBreadcrumbs]="false"
            [disableNextOnClick]="true"
            nextStepLabel="Create"
            (next)="create()"
        >
            <div
                class="customer-request-inner-container"
                [formGroup]="form"
            >
                <div class="customer-request-body">
                    <div class="customer-request-subtitle">
                        Let us know how we can help! Your Visory team will respond as soon as possible.
                    </div>

                    <div
                        class="customer-request-account-dropdown"
                        *ngIf="accounts$ | async; let accounts"
                    >
                        <ng-container *ngIf="accounts.length > 1">
                            <label class="fx-form-label">Account:</label>
                            <div class="v-select-container">
                                <v-select
                                    placeholder="Select an account"
                                    [options]="accounts"
                                    [optionContent]="accountDropdownOptionContent"
                                    [tuiTextfieldCleaner]="false"
                                    formControlName="account"
                                >
                                </v-select>

                                <ng-template
                                    #accountDropdownOptionContent
                                    let-option
                                >
                                    {{ option.label }}
                                </ng-template>
                            </div>
                            <div class="customer-request-account-dropdown-subtitle">
                                Please select the account this request relates to.
                            </div>
                        </ng-container>
                    </div>

                    <div class="quill-modal">
                        <quill-editor-wrapper
                            [message]="form.controls.message"
                            [autoFocusOnInit]="!isMobileView"
                            [messageSizeQuotaInKB]="messageSizeQuotaInKB"
                        ></quill-editor-wrapper>
                    </div>
                </div>
            </div>
        </stepper-step>

        <stepper-step
            label="Request submited"
            [completed]="true"
            [hideFooter]="true"
            [showBreadcrumbs]="false"
        >
            <create-success
                [thread]="createdThread"
                (close)="close()"
                (goToCreate)="goBackToCreate()"
            ></create-success>
        </stepper-step>
    </stepper-layout>

    <!-- Deprecated, should be removed as soon the new POC is rolled out to all customers  -->
    <div *ngIf="!(customerNewRequestStepperVisible$ | async)">
        <div
            class="customer-request-inner-container"
            [formGroup]="form"
        >
            <div class="customer-request-body">
                <div class="customer-request-subtitle">
                    Let us know how we can help! Your Visory team will respond as soon as possible.
                </div>

                <div
                    class="customer-request-account-dropdown"
                    *ngIf="accounts$ | async; let accounts"
                >
                    <ng-container *ngIf="accounts.length > 1">
                        <label class="fx-form-label">Account:</label>
                        <div class="v-select-container">
                            <v-select
                                placeholder="Select an account"
                                [options]="accounts"
                                [optionContent]="accountDropdownOptionContent"
                                [tuiTextfieldCleaner]="false"
                                formControlName="account"
                            >
                            </v-select>

                            <ng-template
                                #accountDropdownOptionContent
                                let-option
                            >
                                {{ option.label }}
                            </ng-template>
                        </div>
                        <div class="customer-request-account-dropdown-subtitle">
                            Please select the account this request relates to.
                        </div>
                    </ng-container>
                </div>

                <div class="quill-modal">
                    <quill-editor-wrapper
                        [message]="form.controls.message"
                        [autoFocusOnInit]="!isMobileView"
                        [messageSizeQuotaInKB]="messageSizeQuotaInKB"
                    ></quill-editor-wrapper>
                </div>

                <div class="customer-request-footer">
                    <div class="customer-request-footer-buttons customer-request-footer-buttons--mobile">
                        <button
                            class="fx-btn fx-btn--primary"
                            role="button"
                            [analyticsClick]="gaEvents.CUSTOMER_REQUEST_CREATE_SEND"
                            (click)="create()"
                            [disabled]="!form.valid || loader.counter"
                        >
                            <i class="fx-btn-icon las la-paper-plane"></i>
                            Send
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</sidebar-modal-layout>
