<ng-container *ngIf="tableData.data">
    <loader-icon
        *ngIf="loader.counter"
        color="light"
    ></loader-icon>
    <h2>Active workflows</h2>
    <fx-table
        [tableData]="tableData"
        [trackBy]="'id' | trackProperty"
    >
        <ng-container
            *fxColumn="
                'includeThread';
                label: tableHeaders.replace;
                sortable: true;
                mobileCollapse: true;
                let threadListing
            "
        >
            <div class="threads-col">
                <span class="mobile-cell-label">{{ tableHeaders.replace }}: </span>

                <fx-checkbox
                    style="margin-bottom: 2em"
                    [formControl]="form.controls[threadListing.id]"
                ></fx-checkbox>
            </div>
        </ng-container>

        <ng-container
            *fxColumn="
                'account.label';
                label: tableHeaders.account;
                sortable: true;
                mobileCollapse: true;
                let threadListing
            "
        >
            <div class="threads-col">
                <span class="mobile-cell-label">{{ tableHeaders.account }}: </span>
                <span>
                    {{ threadListing.account?.label || "" }}
                </span>
            </div>
        </ng-container>

        <ng-container
            *fxColumn="'type'; sortable: true; label: tableHeaders.service; mobileCollapse: true; let threadListing"
        >
            <div class="threads-col">
                <span class="mobile-cell-label">{{ tableHeaders.service }}: </span>
                <div class="threads-title-type">
                    <thread-type-badge
                        [threadType]="(threadListing.type | threadTypeName | async) || '...'"
                    ></thread-type-badge>
                </div>
            </div>
        </ng-container>

        <ng-container
            *fxColumn="
                'title';
                label: tableHeaders.timelineTitle;
                sortable: true;
                mobileCollapse: true;
                let threadListing
            "
        >
            <div class="threads-col threads-title">
                <span class="mobile-cell-label">{{ tableHeaders.timelineTitle }}: </span>
                {{ threadListing.title }}
            </div>
        </ng-container>

        <ng-container
            *fxColumn="
                'reviewChange';
                sortable: true;
                label: tableHeaders.reviewChange;
                mobileCollapse: true;
                let threadListing
            "
        >
            <div
                class="threads-col"
                *ngIf="userId$ | async; let userId"
            >
                <span class="mobile-cell-label">{{ tableHeaders.reviewChange }}: </span>
                <div
                    style="display: flex"
                    [ngSwitch]="newUserIds?.length && form.controls[threadListing.id].value"
                >
                    <avatar-pill
                        [disabled]="true"
                        [name]="userId | userToName | async"
                        [id]="userId"
                        [image]="userId | profilePicture | async"
                    ></avatar-pill>

                    <span class="replace-user-separator">→</span>

                    <ng-container *ngSwitchCase="true">
                        <avatar-pill
                            *ngFor="let newUserId of newUserIds"
                            [disabled]="true"
                            [name]="newUserId | userToName | async"
                            [id]="newUserId"
                            [image]="newUserId | profilePicture | async"
                        ></avatar-pill>
                    </ng-container>

                    <div
                        *ngSwitchDefault
                        class="replace-user-no-updates"
                    >
                        No updates
                    </div>
                </div>
            </div>
        </ng-container>
    </fx-table>

    <div class="replace-user-workflows-paginator">
        <fx-paginator
            [enableBack]="paginator.canGoBack$ | async"
            [enableNext]="paginator.canGoNext$ | async"
            (goNext)="paginator.goNext()"
            (goBack)="paginator.goBack()"
        ></fx-paginator>
    </div>
</ng-container>
