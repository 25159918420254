<delphi-logo
    *ngIf="brandingEnabled$ | async"
    label="{{ aiName }} Memories"
    style="margin-top: 2em; font-size: 17px; margin-bottom: 0.5em; display: block"
></delphi-logo>

<ghost-loader
    *ngIf="loader.counter"
    [showAnimation]="true"
    [width]="100"
    shape="square"
    class="ghost-loader"
>
</ghost-loader>

<info-banner
    *ngIf="contextMemories$ | async as context"
    (click)="context?.memories?.length && openModal(context)"
    style="font-size: 14px"
    >{{ context?.memories?.length ? context.summary : "No relevant memories" }}</info-banner
>
