<loader-icon
    *ngIf="loader.counter || !tableData"
    [static]="true"
    color="light"
></loader-icon>

<ng-container *ngIf="!loader.counter && tableData?.data">
    <div
        class="threads threads--with-hover-state"
        *ngIf="tableData.data?.length; else noThreads"
    >
        <fx-table
            [tableData]="tableData"
            [trackBy]="'id' | trackProperty"
            [sortDynamically]="true"
            [sortEnabled]="false"
        >
            <ng-container
                *fxColumn="'threadType'; label: tableHeaders.Service; mobileCollapse: true; let threadListing"
            >
                <div class="threads-col threads-type">
                    <span class="mobile-cell-label">{{ tableHeaders.Service }}: </span>
                    <a
                        [routerLink]="['/timelines', threadListing.id]"
                        *ngIf="!threadListing.taskId"
                    >
                        <div class="threads-type-badge">
                            <thread-type-badge
                                [threadType]="(threadListing.type | threadTypeName | async) || '...'"
                            ></thread-type-badge>
                        </div>
                    </a>
                    <a
                        [routerLink]="['/', 'timelines', threadListing.threadId, 'cards', threadListing.cardId]"
                        *ngIf="!!threadListing.taskId"
                    >
                        <div class="threads-type-badge">
                            <thread-type-badge
                                [threadType]="(threadListing.threadTypeLabel | threadTypeName | async) || '...'"
                            ></thread-type-badge>
                        </div>
                    </a>
                </div>
            </ng-container>

            <ng-container *ngIf="!hideStatusCol">
                <ng-container
                    *fxColumn="
                        'status';
                        label: tableHeaders.Task;
                        mobileCollapse: true;
                        sortAnalyticsEvent: gaEvents.DASHBOARD_FILTER_STATUS;
                        let threadListing
                    "
                >
                    <div class="threads-col threads-status threads-container">
                        <span class="mobile-cell-label">{{ tableHeaders.Task }}: </span>

                        <a
                            [routerLink]="['/timelines', threadListing.id]"
                            *ngIf="!threadListing.taskId"
                        >
                            <thread-workflow-status
                                *ngIf="threadListing?.workflow"
                                [thread]="threadListing"
                                [workflow]="threadListing.workflow"
                                [shouldShowTitle]="true"
                                [enableDotStepper]="true"
                                [enablePopUp]="
                                    (FEATURE_FLAGS.EnableWorkflowListAndStepper | featureEnabled | async) ? false : true
                                "
                                [fullWidth]="true"
                                [overrideStepperTitleColor]="true"
                                [role]="threadListing | threadRole : userId"
                            ></thread-workflow-status>
                        </a>
                        <a
                            [routerLink]="['/', 'timelines', threadListing.threadId, 'cards', threadListing.cardId]"
                            *ngIf="!!threadListing.taskId"
                            class="threads-task-label"
                        >
                            {{ threadListing.taskLabel }}
                        </a>
                    </div>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="workflowDashboardDueDateEnabled$ | async">
                <ng-container
                    *fxColumn="'dueDate'; label: tableHeaders.DueDate; mobileCollapse: true; let threadListing"
                >
                    <div class="threads-col threads-due-date">
                        <span class="mobile-cell-label">{{ tableHeaders.DueDate }}: </span>

                        <a [routerLink]="['/timelines', threadListing.id]">
                            <div
                                class="threads-overdue-badge"
                                *ngIf="role | permissions : 'UpdateInternalWorkflow' | async"
                            >
                                <v-badge
                                    *ngIf="threadListing?.isOverdue"
                                    [color]="badgeColors.deepOrange"
                                    value="{{ WorkflowConstants.Overdue | uppercase }}"
                                >
                                </v-badge>
                            </div>

                            <div
                                *ngIf="threadListing?.currentStepDueDate"
                                class="threads-due-dates"
                            >
                                <span class="threads-due-date-day">
                                    {{ threadListing.currentStepDueDate | date : "ccc, d LLL" }}&nbsp;
                                </span>
                                <span class="threads-due-date-time">{{
                                    threadListing.currentStepDueDate | date : "h:mma"
                                }}</span>
                            </div>
                        </a>
                    </div>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="assignedTo === AssignedTo.Others">
                <ng-container
                    *fxColumn="
                        'assignee';
                        label: tableHeaders.Assignee;
                        sortable: false;
                        mobileCollapse: true;
                        let threadListing
                    "
                >
                    <div
                        class="threads-col"
                        *ngIf="$any(threadListing?.avatars$ | async); let avatars"
                    >
                        <span
                            *ngIf="avatars?.length"
                            class="mobile-cell-label"
                            >{{ tableHeaders.Assignee }}:
                        </span>
                        <a
                            [routerLink]="['/timelines', threadListing.id]"
                            class="threads-assignee"
                        >
                            <ng-container *ngIf="avatars">
                                <fx-avatar-multi
                                    *ngIf="avatars?.length; else singleAvatar"
                                    [content]="avatars"
                                ></fx-avatar-multi>
                                <ng-template #singleAvatar>
                                    <fx-avatar
                                        *ngIf="avatars[0]"
                                        [name]="avatars?.name"
                                        size="small"
                                        [image]="avatars?.image"
                                    ></fx-avatar>
                                </ng-template>
                            </ng-container>
                        </a>
                    </div>
                </ng-container>
            </ng-container>

            <ng-container
                *fxColumn="
                    'workflow';
                    label: tableHeaders.Workflow;
                    sortable: false;
                    mobileCollapse: true;
                    let threadListing
                "
            >
                <div class="threads-col">
                    <span class="mobile-cell-label">{{ tableHeaders.Workflow }}: </span>

                    <a
                        [routerLink]="['/timelines', threadListing.id]"
                        class="threads-workflow"
                        *ngIf="!threadListing.taskId"
                    >
                        <b> {{ threadListing.title }}</b>
                        <span>{{ threadListing.account?.label }}</span>
                    </a>
                    <a
                        [routerLink]="['/', 'timelines', threadListing.threadId, 'cards', threadListing.cardId]"
                        class="threads-workflow"
                        *ngIf="!!threadListing.taskId"
                    >
                        <b> {{ threadListing.threadName }}</b>
                        <span>{{ threadListing.accountLabel }}</span>
                    </a>
                </div>
            </ng-container>

            <ng-container
                *fxColumn="
                    'unresolvedNotifications';
                    label: tableHeaders.Activity;
                    mobileCollapse: true;
                    sortAnalyticsEvent: gaEvents.DASHBOARD_UNREAD_MESSAGES;
                    let threadListing
                "
            >
                <div class="threads-col">
                    <span class="mobile-cell-label">{{ tableHeaders.Activity }}: </span>
                    <div class="threads-unread-icon">
                        <a
                            *ngIf="threadListing.unresolvedNotifications"
                            [analyticsClick]="gaEvents.DASHBOARD_UNREAD_MESSAGES"
                            [routerLink]="['/timelines', threadListing.id]"
                            class="threads-unread-icon-badge"
                        >
                            <i class="las la-bell"></i>
                            {{ threadListing.unresolvedNotifications }}
                        </a>
                    </div>
                </div>
            </ng-container>
        </fx-table>
    </div>

    <ng-template #noThreads>
        <div class="threads-empty">
            <empty-state
                title="You're all up to date!"
                [htmlText]="
                    assignedTo === AssignedTo.You
                        ? EmptyStateConstants.AssignedToYou
                        : EmptyStateConstants.AssignedToOthers
                "
                [imageType]="emptyState.UpToDate"
            >
            </empty-state>
        </div>
    </ng-template>
</ng-container>
