import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChildren, QueryList } from "@angular/core";
import { environmentCommon } from "../../../environment/environment.common";
import { PermissionService } from "../../services/permissions.service";
import { AuthService } from "../../../findex-auth";
import { filter, map, shareReplay, startWith, switchMap, take } from "rxjs/operators";
import { Observable, Subscription } from "rxjs";
import { QuillViewComponent, QuillViewHTMLComponent } from "ngx-quill";
import Quill from "quill";

const MENTION_CLASS = "mention";

enum MouseEventType {
    MOUSEOVER = "mouseover",
    MOUSEOUT = "mouseout",
    CLICK = "click",
}

@Component({
    selector: "quill-view-wrapper",
    templateUrl: "./quill-view-wrapper.component.html",
})
export class QuillViewWrapperComponent implements AfterViewInit, OnDestroy, OnInit {
    @Input() useHtml = false;
    @Input() ariaLabel = "message";
    @Input() content: string;

    @ViewChildren("quillView")
    quillViews?: QueryList<QuillViewComponent | QuillViewHTMLComponent>;

    mentionElement: Element | null = null;
    tooltipData: { name: string; userId: string; title: string } = null;

    readonly quillStyles = environmentCommon.quillConfig.styling;
    private editor: Quill | null = null;
    private eventListeners: { [key in MouseEventType]?: EventListener } = {};
    private canNavigateToProfile$: Observable<boolean>;
    private editorSubscription: Subscription;

    constructor(private authService: AuthService, private permissionService: PermissionService) {}

    ngOnInit(): void {
        this.canNavigateToProfile$ = this.authService.getGlobalRole().pipe(
            switchMap((role) => this.permissionService.checkPermissions(role, "ReadRoleAll")),
            shareReplay(1),
        );
    }

    ngAfterViewInit(): void {
        const quillView$ = this.quillViews?.changes.pipe(
            startWith(this.quillViews),
            filter((views) => !!views?.first && views?.first?.onEditorCreated),
            map((views) => views.first),
        );

        this.editorSubscription = quillView$
            .pipe(switchMap((quillView: QuillViewComponent) => this.getQuillEditor(quillView)))
            .subscribe((editor: Quill) => {
                this.setupMentionHandlers(editor);
            });
    }

    ngOnDestroy(): void {
        this.editorSubscription?.unsubscribe();

        this.removeEventListeners();
        this.hideTooltip();
    }

    private getQuillEditor(quillView: QuillViewComponent): Observable<Quill> {
        return quillView.onEditorCreated.pipe(
            startWith(quillView.quillEditor),
            filter((editor) => !!editor),
        );
    }

    private removeEventListeners(): void {
        if (this.editor?.root && this.eventListeners) {
            const elm = this.editor.root;
            Object.entries(this.eventListeners).forEach(([event, listener]) => {
                elm.removeEventListener(event, listener);
            });
        }
    }

    private setupMentionHandlers(editor: Quill): void {
        this.removeEventListeners();
        this.editor = editor;

        const events = [MouseEventType.MOUSEOVER, MouseEventType.MOUSEOUT, MouseEventType.CLICK];
        for (const eventType of events) {
            this.eventListeners[eventType] = this.addEventHandler(eventType, editor);
        }
    }

    private addEventHandler(eventType: string, editor: Quill): EventListener {
        const handler = (event: Event): void => {
            const target = event.target as HTMLElement;
            if (!this.isMentionElement(target)) {
                return;
            }

            switch (eventType) {
                case "mouseover":
                    this.handleMouseOver(target);
                    break;
                case "mouseout":
                    this.handleMouseOut();
                    break;
                case "click":
                    event.stopPropagation();
                    this.handleClick(target);
                    break;
            }
        };

        editor?.root?.addEventListener(eventType, handler);

        return handler;
    }

    private isMentionElement(element: HTMLElement): boolean {
        return element.classList.contains(MENTION_CLASS) || element.closest(`.${MENTION_CLASS}`) !== null;
    }

    private getMentionElement(element: HTMLElement): HTMLElement | null {
        return element.classList.contains(MENTION_CLASS) ? element : element.closest(`.${MENTION_CLASS}`);
    }

    private handleMouseOver(element: HTMLElement): void {
        const mentionElement = this.getMentionElement(element);
        if (!mentionElement) {
            return;
        }

        const name = mentionElement.getAttribute("data-value");
        const userId = mentionElement.getAttribute("data-id");
        const title = mentionElement.getAttribute("data-title");

        this.showTooltip(mentionElement, { name, userId, title });
    }

    private showTooltip(targetElement: HTMLElement, data: { name: string; userId: string; title: string }): void {
        this.mentionElement = targetElement;
        this.tooltipData = data;
    }

    private hideTooltip(): void {
        this.tooltipData = null;
        this.mentionElement = null;
    }

    private handleMouseOut(): void {
        this.hideTooltip();
    }

    private handleClick(element: HTMLElement): void {
        const mentionElement = this.getMentionElement(element);
        if (!mentionElement) {
            return;
        }

        this.canNavigateToProfile$.pipe(take(1)).subscribe((canNavigate) => {
            if (!canNavigate) {
                return;
            }

            const userId = mentionElement.getAttribute("data-id");
            window.open(`/admin/clients/${userId}/details`, "_blank");
        });
    }
}
