import { IWorkflowVariation, RoleAssigneeVariations } from "@visoryplatform/threads";

import { AssigneeExtensionHelpers } from "@visoryplatform/workflow-core";
import { RolesAssigneeFormValue } from "../../account/components/workflow-configuration-roles/workflow-configuration-roles.component";

export class AssigneeVariationsService {
    getAssigneeTokensFromWorkflowTokens(workflowVariations: IWorkflowVariation[]): RolesAssigneeFormValue | null {
        if (!workflowVariations?.length) {
            return null;
        }

        const assigneeVariations = Object.keys(RoleAssigneeVariations);
        const assigneeTokens = workflowVariations.reduce<RolesAssigneeFormValue>(
            (acc, token) => this.getRolesAssigneeFormValues(assigneeVariations, token, acc),
            {},
        );

        return Object.keys(assigneeTokens).length ? assigneeTokens : null;
    }

    mapRoleAssigneeToConfigTokens(
        workflowVariations: IWorkflowVariation[],
        selectedRoleAssignees?: RolesAssigneeFormValue | null,
    ): IWorkflowVariation[] {
        if (!workflowVariations || !selectedRoleAssignees) {
            return [];
        }

        return workflowVariations.reduce<IWorkflowVariation[]>(
            (roleAssigneeFormValue, workflowVariation) =>
                this.updateConfigTokensWithRoleAssigneeData(
                    workflowVariation,
                    selectedRoleAssignees,
                    roleAssigneeFormValue,
                ),
            [],
        );
    }

    private getRolesAssigneeFormValues(
        assigneeVariations: string[],
        workflowVariation: IWorkflowVariation,
        roleAssigneeFormValue: RolesAssigneeFormValue,
    ): RolesAssigneeFormValue {
        const [configTokenKey] = Object.keys(workflowVariation.configTokens);
        const configTokenData = workflowVariation.configTokens[configTokenKey];

        const hasRoleAssigneeVariation = assigneeVariations.some(
            (variation) => variation in workflowVariation.configTokens,
        );
        const isValidAssigneeInputData = AssigneeExtensionHelpers.isValidAssigneeInputData(configTokenData);

        if (hasRoleAssigneeVariation && isValidAssigneeInputData) {
            roleAssigneeFormValue = {
                ...roleAssigneeFormValue,
                [configTokenKey]: configTokenData.assignees,
            };
        }
        return roleAssigneeFormValue;
    }

    private updateConfigTokensWithRoleAssigneeData(
        workflowVariation: IWorkflowVariation,
        selectedRoleAssignees: RolesAssigneeFormValue,
        roleAssigneeFormValue: IWorkflowVariation[],
    ): IWorkflowVariation[] {
        const [configTokenKey] = Object.keys(workflowVariation.configTokens);
        const configTokenData = workflowVariation.configTokens[configTokenKey];
        const assignees = selectedRoleAssignees[configTokenKey];

        if (configTokenKey && assignees) {
            const configTokens = { [configTokenKey]: { ...configTokenData, assignees } };
            roleAssigneeFormValue.push({
                ...workflowVariation,
                configTokens,
            });
        } else {
            roleAssigneeFormValue.push(workflowVariation);
        }

        return roleAssigneeFormValue;
    }
}
