import { Component, Input, OnDestroy } from "@angular/core";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { Observable, Subscription } from "rxjs";
import { UserAssigneeService } from "../../services/user-assignee.service";
import { IWorkflowConfiguration } from "@visoryplatform/threads";
import { switchMap } from "rxjs/operators";
import { Paginator } from "projects/portal-modules/src/lib/shared/services/paginator";
import { IPaginated } from "@visoryplatform/datastore-types";
import { MatTableDataSource } from "@angular/material/table";
import { ControlValueAccessor, FormControl, FormRecord, NG_VALUE_ACCESSOR } from "@angular/forms";
import { UserProfileRouteService } from "projects/portal-modules/src/lib/user-profile/services/user-profile-route.service";

@Component({
    selector: "bulk-replace-user-configurations",
    templateUrl: "./bulk-replace-user-configurations.component.html",
    styleUrls: ["./bulk-replace-user-configurations.component.scss"],
    providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: BulkReplaceUserConfigurationsComponent }],
})
export class BulkReplaceUserConfigurationsComponent implements OnDestroy, ControlValueAccessor {
    @Input() newUserIds: string[];

    loader = new Loader();

    tableHeaders = {
        service: "Service",
        reviewChange: "Change",
        timelineTitle: "Workflow",
        replace: "Replace",
        account: "Account",
    };

    form = new FormRecord<FormControl<boolean>>({});

    tableData = new MatTableDataSource<IWorkflowConfiguration>();

    paginator = new Paginator<IWorkflowConfiguration>(10);
    userConfigurations$: Observable<IWorkflowConfiguration[]>;
    userId$: Observable<string>;

    private userConfigurationsSub: Subscription;
    private onChange?: (excludeThreadIds: string[]) => void;
    private onTouch?: () => void;

    constructor(
        private userAssigneeService: UserAssigneeService,
        private userProfileRouteService: UserProfileRouteService,
    ) {
        this.userId$ = this.userProfileRouteService.getUserId();

        this.userConfigurations$ = this.paginator.wrap();
        this.paginator.refresh((page) => this.getConfigurationListing(page));

        this.userConfigurationsSub = this.userConfigurations$.subscribe((configurations) => {
            const configurationIds = configurations.map((configuration) => configuration.id);
            for (const configurationId of configurationIds) {
                if (!this.form.controls[configurationId]) {
                    this.form.addControl(configurationId, new FormControl<boolean>(true));
                }
            }

            this.tableData.data = configurations;
        });

        this.form.valueChanges.subscribe(() => {
            const excludeConfigurationIds = Object.entries(this.form.controls)
                .filter(([_, control]) => !control.value)
                .map(([configurationId]) => configurationId);
            this.onChange?.(excludeConfigurationIds);
            this.onTouch?.();
        });
    }

    ngOnDestroy(): void {
        this.userConfigurationsSub?.unsubscribe();
    }

    writeValue(excludeConfigurationIds: string[]): void {
        for (const configurationId of excludeConfigurationIds) {
            if (!this.form.controls[configurationId]) {
                this.form.addControl(configurationId, new FormControl<boolean>(false));
            } else {
                this.form.controls[configurationId].setValue(false);
            }
        }
    }

    registerOnChange(fn: (excludeConfigurationIds: string[]) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    private getConfigurationListing(page: string): Observable<IPaginated<IWorkflowConfiguration>> {
        return this.userId$.pipe(
            switchMap((userId) => this.loader.wrap(this.userAssigneeService.listConfigurations(userId, page))),
        );
    }
}
