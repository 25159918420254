<centered-modal-layout
    [disableSave]="false"
    cancelButtonTitle="Close"
    saveButtonTitle="Create"
    [loading]="!!loader.counter"
    (save)="
        create(response.startDate, response.endDate, response.description, response.statementLines, response.queries)
    "
    (close)="dialogRef.close()"
>
    <div modal-header>
        <request-header
            title="Create transaction queries"
            [isComplete]="false"
            [completedStatusLabel]="RequestStatuses.Pending"
            [accountName]="thread?.account?.label || ''"
        ></request-header>

        <banner-relevant-memories
            *ngIf="(enableMemories$ | async) && thread?.id"
            [threadId]="thread?.id"
            [accountId]="thread?.account?.id"
        ></banner-relevant-memories>
    </div>

    <div modal-content>
        <edit-transaction-queries
            [thread]="thread"
            [cardDescription]="cardDescription"
            (update)="handleUpdate($event)"
        ></edit-transaction-queries>
    </div>
</centered-modal-layout>
