import { ErrorHandler, Inject, Injectable } from "@angular/core";
import { ErrorService } from "projects/portal-modules/src/lib/shared/services/error.service";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";

const FILTER_ERRORS = ["ClientAuthError: Token calls are blocked in hidden iframes"];
@Injectable()
export class ThreadsErrorHandler extends ErrorHandler {
    constructor(private errorService: ErrorService, @Inject(ENVIRONMENT) environment: EnvironmentSpecificConfig) {
        super();

        Sentry.init({
            dsn: environment.sentry,
            environment: environment.stage,
            enabled: ["uat", "prod"].includes(environment.stage),
            release: environment.appVersion,
            integrations: [
                new Integrations.BrowserTracing({
                    tracingOrigins: [], //If we add tracing to backend add domains here to track across project
                    routingInstrumentation: Sentry.routingInstrumentation,
                }),
            ],
            tracesSampleRate: 0.15,
            ignoreErrors: FILTER_ERRORS,
        });
    }

    handleError(err: any) {
        this.errorService.handleError(err);
    }
}
