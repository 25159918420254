<div
    class="app-container chat"
    *ngIf="{ accountId: accountId$ | async, actorId: actorId$ | async } as context"
>
    <div class="conversation-header">
        <div>
            <a [routerLink]="['..']">
                <div style="display: flex">
                    <h3 [ngSwitch]="brandingEnabled$ | async">
                        <delphi-logo
                            *ngSwitchCase="true"
                            label="Ask {{ environment.aiName }}"
                        ></delphi-logo>
                        <span *ngSwitchDefault>Ask</span>
                    </h3>
                </div>
                <p><small>← Back</small></p>
            </a>
            <div></div>
        </div>
    </div>

    <div
        class="messages"
        #scrollContainer
    >
        <loader-icon
            *ngIf="loader.counter"
            color="light"
        ></loader-icon>

        <ng-container *ngIf="messages$ | async as messages">
            <app-card
                *ngFor="let message of messages; trackBy: 'id' | trackProperty"
                [useQuillHtml]="true"
                [description]="message.body"
                [timestamp]="message.createdAt"
                [conversation]="false"
                [image]="(message.role === 'assistant' ? systemId : (actorId$ | async)) | profilePicture | async"
                [align]="message.role === 'assistant' ? 'left' : 'right'"
                [name]="(message.role === 'assistant' ? systemId : (actorId$ | async)) | userToName | async"
            ></app-card>
        </ng-container>

        <div
            *ngIf="aiTyping.counter"
            class="gpt-loading"
        >
            Delphi is thinking...
        </div>

        <div class="scroll-anchor"></div>
    </div>

    <div
        *ngIf="chatId$ | async as chatId"
        class="message-controls"
    >
        <textarea
            class="fx-form-input"
            textareaAutoresize
            placeholder="Ask a question"
            [formControl]="newMessage"
        ></textarea>

        <div class="message-controls-send">
            <button
                (click)="addMessage(context.actorId, chatId, newMessage.value, context.accountId)"
                class="fx-btn fx-btn--full-width fx-btn--primary"
                role="button"
            >
                Send
            </button>
        </div>
    </div>
</div>
