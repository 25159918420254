<div
    *ngIf="form?.controls?.filters"
    class="timeline-list"
>
    <div class="timeline-list-header">
        <h2>Workflows</h2>
    </div>

    <div
        *ngIf="{ globalRole: globalRole$ | async } as context"
        class="timeline-list-top-panel"
        [formGroup]="form"
    >
        <timelines-filters formControlName="filters"></timelines-filters>

        <button
            class="timeline-list-top-panel-clear"
            (click)="resetFilters()"
        >
            <i class="las la-sync-alt la-lg"></i>
            <span>Reset filters</span>
        </button>
    </div>

    <timelines-paginated
        *ngIf="formValues$ | async; let formValues"
        [filters]="formValues"
    ></timelines-paginated>
</div>

<loader-icon
    *ngIf="loader.counter"
    color="light"
></loader-icon>
