import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ICustomer, IPaymentMethod } from "@visoryplatform/payments-service-sdk";
import { MatDialog } from "@angular/material/dialog";
import { EditBillingDetailsComponent } from "../edit-billing-details/edit-billing-details.component";
import { EditBillingContactDetailsComponent } from "../edit-billing-contact-details/edit-billing-contact-details.component";
import { AccountRouteService } from "../../../../portal-modules/src/lib/account/services/account-route.service";
import { Observable } from "rxjs";
import { Account } from "@visoryplatform/threads";
import { map } from "rxjs/operators";

@Component({
    selector: "app-payments-subscriptions",
    templateUrl: "./payments-subscriptions.component.html",
    styleUrls: ["./payments-subscriptions.component.scss"],
})
export class PaymentSubscriptionsComponent {
    @Input() packageDisplayName: string;
    @Input() userPaymentDetails: ICustomer;
    @Input() paymentMethods: IPaymentMethod[];
    @Input() hidePaymentMethods: boolean;

    @Output() updatePaymentMethods = new EventEmitter<void>();

    account$: Observable<Account>;
    paymentMethodError: string;
    errorMessage: string;

    constructor(private dialog: MatDialog, private accountRouteService: AccountRouteService) {
        this.account$ = this.accountRouteService.getAccount();
    }

    editBillingDetails(customerId: string, paymentMethods: IPaymentMethod[]) {
        const config = {
            backdropClass: "modal-backdrop",
            panelClass: ["centered-modal", "centered-modal--two-columns"],
            data: {
                customerId,
                paymentMethods,
                currency: this.userPaymentDetails.currency,
                accountId$: this.account$.pipe(map((account) => account.id)),
            },
        };

        return this.dialog
            .open(EditBillingDetailsComponent, config)
            .afterClosed()
            .subscribe((success) => {
                if (success) {
                    this.updatePaymentMethods.emit();
                }
            });
    }

    editBillingContactDetails(customerId: string, name: string, email: string, address: ICustomer["address"]) {
        const config = {
            backdropClass: "modal-backdrop",
            panelClass: ["modal-container"],
            maxWidth: "420px",
            height: "auto",
            data: {
                customerId,
                name,
                email,
                address,
            },
        };

        return this.dialog
            .open(EditBillingContactDetailsComponent, config)
            .afterClosed()
            .subscribe((userPaymentDetails?: ICustomer) => {
                if (userPaymentDetails) {
                    this.userPaymentDetails = userPaymentDetails;
                    this.updatePaymentMethods.emit();
                }
            });
    }
}
