<request
    (save)="save()"
    (close)="close()"
    [title]="modalTitle.Request"
    [subheaderDescription]="description"
    [loading]="!!loader?.counter"
    [accountName]="accountName"
    [disableSave]="!form?.valid"
    [saveButtonTitle]="buttonLabel.Create"
    [analyticsPrefix]="analyticsTags.analyticsPrefix"
    *ngIf="modalData"
>
    <form [formGroup]="form">
        <div class="request-common-group">
            <div class="request-common-request-info-fields">
                <label
                    class="fx-form-label"
                    for="vaultTitle"
                >
                    Request title
                </label>
                <div class="fx-form-control">
                    <input
                        class="fx-form-input"
                        formControlName="title"
                        id="vaultTitle"
                        name="vaultTitle"
                        placeholder="Add a title to this request"
                    />
                </div>
                <div
                    *ngIf="form.controls.title.hasError('required') && form.controls.title.touched"
                    class="fx-form-validation"
                >
                    Request title is required.
                    <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                </div>
            </div>
        </div>

        <div class="request-common-group">
            <div class="request-common-label request-common-group-header">Note or message</div>
            <request-view-reply [cardDescription]="form.controls.cardDescription"></request-view-reply>
        </div>
        <div class="request-common-group">
            <div>
                <div class="request-common-label request-common-group-header">Request attachments</div>
                <file-input
                    (file)="attachFile($event)"
                    [analyticsPrefix]="analyticsTags.analyticsPrefix"
                ></file-input>
            </div>
        </div>

        <div class="request-common-group">
            <div class="create-request-attachments">
                <div
                    *ngFor="let attachment of attachments; let i = index"
                    class="create-request-attachments-attachment"
                >
                    {{ attachment.name }}
                    <button
                        (click)="removeAttachment(i)"
                        analyticsClick="{{ analyticsTags.analyticsPrefix }}_remove-file"
                        class="create-request-attachments-remove-file"
                    >
                        <i class="las la-times icon-size"></i>
                    </button>
                </div>
            </div>
        </div>

        <div class="request-common-secondary-header">
            <h1>To-do list</h1>
        </div>

        <div class="request-common-secondary-sub-header">
            Create a list of action items for this request. Press Enter to create new items.
        </div>

        <div
            class="request-common-todo-list"
            formArrayName="requestItems"
        >
            <fx-todolist>
                <ng-container
                    *ngFor="let item of form.controls.requestItems.controls; let i = index"
                    [formGroupName]="i"
                >
                    <fx-todolist-item
                        formControlName="completed"
                        [shouldShowDelete]="form.controls.requestItems.controls.length > 1"
                        (deleteItem)="removeControl(i)"
                    >
                        <div class="request-common-todo">
                            <div class="request-common-todo-details">
                                <textarea
                                    #todoItemComponents
                                    (keydown)="keyboardEvent($event)"
                                    cdkAutosizeMaxRows="3"
                                    cdkAutosizeMinRows="1"
                                    cdkTextareaAutosize
                                    class="request-common-todo-details-field"
                                    formControlName="description"
                                    placeholder="Type something..."
                                    type="text"
                                ></textarea>
                            </div>
                        </div>
                    </fx-todolist-item>

                    <div
                        *ngIf="item.controls.description.hasError('required') && item.controls.description.touched"
                        class="fx-form-validation request-common-todo-error"
                    >
                        Message is required.
                    </div>
                </ng-container>
            </fx-todolist>

            <button
                (click)="form.controls.requestItems.valid ? addRequestItem(form) : null"
                [ngClass]="{ 'request-common-todo-add-item--disabled': !form.controls.requestItems.valid }"
                class="request-common-todo-add-item"
            >
                {{ buttonLabel.AddItem }}
            </button>
        </div>
    </form>
</request>
