import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { QuillEditorComponent, QuillModule, QuillModules } from "ngx-quill";
import matchUrl from "match-url-wildcard";
import { map, switchMap, take } from "rxjs/operators";
import { ENVIRONMENT } from "src/app/injection-token";
import { environmentCommon, EnvironmentSpecificConfig } from "../../../environment/environment.common";
import { PermissionService } from "../../services/permissions.service";
import { AuthService } from "../../../findex-auth";
import { FeatureFlagService } from "../../../feature-flags/services/feature-flags.service";
import { LaunchDarklyFeatureFlags } from "../../../feature-flags/enums/LaunchDarklyFeatureFlags";
import { combineLatest, Observable, Subscription } from "rxjs";
import { of } from "rxjs";
import { MentionableUser } from "./mentionable-user";
import Quill, { RangeStatic } from "quill";

const MENTION_HEADER = "Suggestions";
const MENTION_HEADER_CLASS = "ql-mention-header";
const MENTION_LIST_ITEM_CLASS = "ql-mention-list-item";
const MENTION_LIST_CLASS = "ql-mention-list";
const MENTION_LIST_CONTAINER_CLASS = "ql-mention-list-container";
const PERMISSION_CREATE_HYPERLINK = "CreateHyperLink";

@Component({
    selector: "quill-editor-wrapper",
    templateUrl: "./quill-editor-wrapper.component.html",
    styleUrls: ["./quill-editor-wrapper.component.scss"],
})
export class QuillEditorWrapperComponent implements OnInit, OnDestroy {
    @Output() userInputEmitter = new EventEmitter<FormControl<string>>();
    @Output() userBlurEmitter = new EventEmitter<FormControl>();
    @Output() error = new EventEmitter<boolean>();

    @ViewChild(QuillEditorComponent, { static: true }) editor: QuillEditorComponent;

    @Input() message!: FormControl<string>;
    @Input() placeholder = "";
    @Input() inline = false;
    @Input() readOnly = false;
    @Input() autoFocusOnInit = true;
    @Input() messageSizeQuotaInKB = 200;
    @Input() mentionableUsers: MentionableUser[] = [];
    @Input() threadType: string; /* temp for feature flag, quill should not know about threads */

    toolbar = false;
    modules$: Observable<QuillModules> = of(environmentCommon.quillConfig.toolbarState.withToolbar);
    quillStyles = environmentCommon.quillConfig.styling;
    characterError = false;
    createHyperlinkSubscription$: Subscription;
    quillInstance: Quill | undefined;

    private mentionObserver: MutationObserver;

    constructor(
        private authService: AuthService,
        private permissionService: PermissionService,
        private featureFlagService: FeatureFlagService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {}

    ngOnInit(): void {
        const user$ = this.authService.getValidUser();
        const role$ = user$.pipe(map((user) => user.globalRole));

        role$
            .pipe(
                switchMap((role) => this.permissionService.checkPermissions(role, PERMISSION_CREATE_HYPERLINK)),
                take(1),
            )
            .subscribe((hasHyperLinkPermission) => this.setToolbarWithLink(hasHyperLinkPermission));
    }

    /**
     * This is a workaround to prevent the default behavior of Quill when a mention is selected and the space key is pressed.
     * @param quill - The Quill instance.
     */
    bindKeyboardEvent(quill: Quill): void {
        if (!quill) {
            return;
        }

        this.quillInstance = quill;

        quill.keyboard.addBinding({ key: " " }, { collapsed: true }, function (range: RangeStatic) {
            const [leaf] = this.quill.getLeaf(range.index);
            if (leaf?.statics?.blotName === "mention") {
                this.quill.insertText(range.index, " ");
                this.quill.setSelection(range.index + 1);
                return false;
            }
            return true;
        });
    }

    ngOnDestroy(): void {
        this.createHyperlinkSubscription$?.unsubscribe();
        if (this.quillInstance?.keyboard) {
            this.quillInstance.keyboard.addBinding({ key: " " }, null);
        }
        const mentionContainer = document.querySelector(".ql-mention-list-container");
        if (mentionContainer) {
            mentionContainer.remove();
        }

        if (this.mentionObserver) {
            this.mentionObserver.disconnect();
        }
    }

    toggleToolbar(): void {
        if (!this.readOnly) {
            this.toolbar = !this.toolbar;
        }
    }

    autoFocus(quill: Quill): void {
        if (!this.readOnly && this.autoFocusOnInit) {
            setTimeout(() => {
                quill.focus();
                const length = quill.getLength();
                quill.setSelection(length, length);
            }, 300);
        }
    }

    onChange(): void {
        this.validateMessageInput();
        this.userInputEmitter.emit(this.message);
    }

    validateMessageInput(): void {
        const message = this.message.value;
        const messageSize = this.calculateMessageSizeInKB(message);
        const isMessageSizeNotValid = messageSize > this.messageSizeQuotaInKB;

        this.characterError = isMessageSizeNotValid;
        this.error.emit(isMessageSizeNotValid);
    }

    private setToolbarWithLink(hasHyperLinkPermission: boolean): void {
        const { withToolbar } = environmentCommon.quillConfig.toolbarState;

        const isUrlValid = (url: string): boolean => {
            const { whitelistedUrls } = this.environment;
            return whitelistedUrls.some((rule) => matchUrl(url, rule));
        };

        const linkHandler = function (value: string): void {
            if (!value) {
                return;
            }

            const href = prompt("Enter the URL");
            if (isUrlValid(href)) {
                this.quill.format("link", href, "user");
            } else {
                console.warn("Invalid URL", href);
            }
        };

        const hyperLinkModule = hasHyperLinkPermission
            ? {
                  container: [...withToolbar.toolbar, ["link"]],
                  handlers: {
                      link: linkHandler,
                  },
              }
            : {};

        const isEnabled$ = combineLatest([
            this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableQuillMentions),
            this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.ConfigureQuillMentionsThreadType),
        ]).pipe(
            map(([mentionsEnabled, threadTypes]) => {
                const isArray = Array.isArray(threadTypes);
                const isThreadTypeEnabled =
                    isArray && (threadTypes?.includes(this.threadType) || threadTypes?.includes("*"));

                return mentionsEnabled && isThreadTypeEnabled;
            }),
        );

        this.modules$ = isEnabled$.pipe(
            map((mentionsEnabled) => {
                return {
                    toolbar: hyperLinkModule,
                    imageCompress: {
                        ...withToolbar.imageCompress,
                    },
                    mention: this.getMentionsModules(mentionsEnabled),
                };
            }),
        );
    }

    private getMentionsModules(mentionsEnabled: boolean): QuillModule | void {
        if (!mentionsEnabled) {
            return;
        }

        return {
            mentionListClass: MENTION_LIST_CLASS,
            allowedChars: /^[A-Za-z\s']*$/,
            showDenotationChar: true,
            spaceAfterInsert: false,
            mentionDenotationChars: ["@"],
            dataAttributes: ["id", "value", "title"],
            minChars: 0,
            maxChars: 31,
            isolateCharacter: true,
            positioningStrategy: "fixed",
            source: (searchTerm: string, renderList: (items: MentionableUser[], searchTerm: string) => void) => {
                const mentions = searchTerm.length === 0 ? this.mentionableUsers : this.searchMentions(searchTerm);

                const mentionsWithTitle = mentions.map((mention) => ({
                    ...mention,
                    title: mention.title || "",
                }));

                renderList(mentionsWithTitle, searchTerm);
            },
            renderItem: (item: MentionableUser) => this.buildMentionItem(item),
            onOpen: () => {
                const mentionContainer = document.querySelector(`.${MENTION_LIST_CONTAINER_CLASS}`);
                if (mentionContainer && !mentionContainer.querySelector(`.${MENTION_HEADER_CLASS}`)) {
                    const header = document.createElement("div");
                    header.classList.add(MENTION_HEADER_CLASS);
                    header.textContent = MENTION_HEADER;
                    mentionContainer.prepend(header);

                    this.setupMentionScrollObserver(mentionContainer);
                }
            },
        };
    }

    private setupMentionScrollObserver(mentionContainer: Element): void {
        if (this.mentionObserver) {
            this.mentionObserver.disconnect();
        }

        this.mentionObserver = new MutationObserver((mutations) => {
            mutations.forEach(() => {
                const selectedItem = mentionContainer.querySelector(`.${MENTION_LIST_ITEM_CLASS}.selected`);
                if (selectedItem) {
                    selectedItem.scrollIntoView({
                        block: "nearest",
                        behavior: "smooth",
                    });
                }
            });
        });

        const mentionList = mentionContainer.querySelector(`.${MENTION_LIST_CLASS}`);
        if (mentionList) {
            this.mentionObserver.observe(mentionList, {
                attributes: true,
                subtree: true,
                attributeFilter: ["class"],
            });
        }
    }

    private buildMentionItem(item: MentionableUser): string {
        return `<div class="ql-mention-item">
                    <div 
                        class="ql-mention-item-avatar fx-avatar fx-avatar--medium"
                        style="background-image: url('${item.avatarUrl}');"
                    ></div>
                    <div class="ql-mention-item-name">
                        ${item.value}
                        ${item.title ? `<div class="ql-mention-item-title">${item.title}</div>` : ""}
                    </div>
                </div>`;
    }

    private searchMentions(searchTerm: string): MentionableUser[] {
        if (!this.mentionableUsers) {
            return [];
        }

        const lowerTerm = searchTerm.toLowerCase().replace(/[^A-Za-z]/g, "");

        return this.mentionableUsers.filter((user) =>
            user?.value
                ?.toLowerCase()
                .replace(/[^A-Za-z]/g, "")
                .includes(lowerTerm),
        );
    }

    private calculateMessageSizeInKB(payload: string): number {
        if (!payload) {
            return 0;
        }

        const bytesInAKB = 1024;
        const payloadLength = payload.length;
        const kb = (payloadLength / bytesInAKB).toFixed(2);

        return Number(kb);
    }
}
