import { Component, OnDestroy, Inject, OnInit } from "@angular/core";
import { Subscription, Observable, combineLatest } from "rxjs";
import { IThreadCard } from "@visoryplatform/threads/dist/interfaces/IThreadCard";
import { IParticipantListing, Role, IThread, CardReply, CardStatus, VideoChatAction } from "@visoryplatform/threads";
import { VcStateBuilder } from "../../services/vc-state-builder";
import { filter, map, shareReplay, switchMap, take } from "rxjs/operators";
import { THREAD_CARD_RESOURCES, CardResources } from "projects/portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { VideoChatService } from "../../services/video-chat.service";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { AppUser, AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { ThreadCardService } from "projects/portal-modules/src/lib/threads-ui/services/thread-card.service";
import { ActionableCardComponent } from "projects/portal-modules/src/lib/shared/components/actionable-card/actionable-card.component";
import { ISlot } from "projects/default-plugins/calendar/services/calendar.service";
import { ILibrary, TaskAction } from "projects/portal-modules/src/lib/plugins";
import { TASK_ACTION_LIBRARY } from "src/app/injection-token";
import { TaskActionService } from "projects/portal-modules/src/lib/shared/components/actionable-card/task-action.service";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import { ThreadsService } from "projects/portal-modules/src/lib/threads-ui/services/threads.service";

@Component({
    selector: "vc-card",
    templateUrl: "./vc-card.component.html",
    styleUrls: ["vc-card.component.scss"],
})
export class VcCardComponent extends ActionableCardComponent<void> implements OnDestroy, OnInit {
    thread$: Observable<IThread>;
    card$: Observable<IThreadCard>;
    user$: Observable<AppUser>;
    replies$: Observable<CardReply[]>;
    role: Role;
    loader = new Loader();
    errorMessage: string;
    CardStatus = CardStatus;
    showJoinCall$: Observable<boolean>;
    showEndCall$: Observable<boolean>;
    modelBuilder = new VcStateBuilder();
    vcDetails$ = this.modelBuilder.getState();

    private fullscreenSub: Subscription;
    private eventSub: Subscription;
    private userSub: Subscription;

    constructor(
        @Inject(THREAD_CARD_RESOURCES) private cardResources: CardResources,
        @Inject(TASK_ACTION_LIBRARY) protected taskActions: ILibrary<TaskAction<ISlot>>,
        private authService: AuthService,
        private cardService: ThreadCardService,
        private videoChatService: VideoChatService,
        protected taskActionService: TaskActionService,
        private threadsService: ThreadsService,
        private permissionService: PermissionService,
    ) {
        super(cardResources, taskActionService);
    }

    ngOnInit() {
        const { threadId, cardId, thread$, card$, events$, navigateTo$, role, replies$: replies } = this.cardResources;

        this.thread$ = thread$;
        this.card$ = card$;
        this.role = role;
        this.replies$ = replies;
        this.user$ = this.authService.getUser().pipe(filter((user) => !!user));
        this.fullscreenSub = navigateTo$.subscribe(() => this.openFullscreen(true));
        this.eventSub = events$.subscribe((event) => this.modelBuilder.addEvent(event));
        this.modelBuilder.setThreadAndState(threadId, cardId);

        //I'm guessing eventually this will change to be other participants in the call but not sure on UI
        //But then what if you are the first to join? UI question + need ED-248
        this.userSub = combineLatest([this.user$, this.thread$])
            .pipe(filter(([user]) => !!user))
            .subscribe(([user, thread]) => this.setCaller(thread.participants, user.id));

        const isThreadActive$ = this.thread$.pipe(
            map((thread) => this.threadsService.isThreadActive(thread)),
            shareReplay(1),
        );
        const joinVideoPermission$ = this.permissionService.checkPermissions(this.role, "JoinVcSession");
        const endVideoPermission$ = this.permissionService.checkPermissions(this.role, "EndSession");

        this.showJoinCall$ = combineLatest([isThreadActive$, joinVideoPermission$]).pipe(
            map(([isThreadActive, hasPermission]) => isThreadActive && hasPermission),
        );

        this.showEndCall$ = combineLatest([isThreadActive$, endVideoPermission$]).pipe(
            map(([isThreadActive, hasPermission]) => isThreadActive && hasPermission),
        );
    }

    ngOnDestroy() {
        if (this.fullscreenSub) {
            this.fullscreenSub.unsubscribe();
        }
        if (this.eventSub) {
            this.eventSub.unsubscribe();
        }
        if (this.userSub) {
            this.userSub.unsubscribe();
        }
    }

    openFullscreen(join?: boolean) {
        if (join) {
            this.action(VideoChatAction.JOIN_CALL);
        } else {
            this.action(VideoChatAction.START_CALL);
        }
    }

    terminateSession(): void {
        const detailSub = this.vcDetails$
            .pipe(
                take(1),
                switchMap((detail) => {
                    const threadId = detail.threadId;
                    const cardId = detail.cardId;
                    const { sessionId } = detail;

                    const terminate$ = this.videoChatService.terminateSession(sessionId, threadId, cardId);
                    return this.loader.wrap(terminate$);
                }),
            )
            .subscribe(() => {
                detailSub.unsubscribe();
            });
    }

    actionCallback(actionId: string): void {
        if (actionId === VideoChatAction.JOIN_CALL) {
            this.action(VideoChatAction.END_SESSION);
        }
    }

    async cancelCard(threadId: string, cardId: string) {
        try {
            this.errorMessage = "";
            this.loader.show();
            await this.cardService.cancelCard(threadId, cardId).toPromise();
        } catch {
            this.errorMessage = "Sorry, something went wrong";
        } finally {
            this.loader.hide();
        }
    }

    private setCaller(participants: IParticipantListing[], excludeId: string): void {
        //This is basically a temp hack
        //What do we want to show when there is more than one participant?? UI Question
        let found = participants.find((participant) => participant.id !== excludeId);
        if (!found) {
            found = participants.find(() => true);
        }

        if (!found || !found.profile || !found.profile.name) {
            return;
        }
        this.modelBuilder.setCaller(found.profile.name, found.profile.title);
    }
}
