import { Injectable } from "@angular/core";
import {
    DUE_DATE_EXTENSION_TYPE,
    IStep,
    AssigneeExtensionHelpers,
    IWorkflowDesign,
    WorkflowGraphService,
    WorkflowTypesService,
} from "@visoryplatform/workflow-core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { StepDataForm } from "../../create-thread/types/StepDataForm";
import { DueDateAnchor, IWorkflowDueDate, WorkflowDueDateService } from "@visoryplatform/threads";
import { DateTime } from "luxon";

@Injectable({ providedIn: "root" })
export class WorkflowFormsService {
    getFormValues(
        startDate: DateTime,
        calculationPoint: DueDateAnchor,
        workflow: IWorkflowDesign,
        _clonedWorkflowCreatedAt?: string,
        isAssigneesRequired?: boolean,
    ): Record<string, FormGroup<StepDataForm>> {
        //TODO: _clonedWorkflowCreatedAt?
        const workflowSteps = WorkflowGraphService.orderWorkflowSteps(workflow).filter(
            (step) => !WorkflowTypesService.isSystemStep(step.id),
        );
        const dueDates = WorkflowDueDateService.getStepDates(calculationPoint, startDate, workflow, null);

        return workflowSteps.reduce((formVal, step) => {
            const assigneeControl = this.getAssigneeControl(step, isAssigneesRequired);
            const dueDateControl = this.getDueDateControl(step, dueDates);

            return {
                ...formVal,
                [step.id]: new FormGroup<StepDataForm>({ ...dueDateControl, ...assigneeControl }),
            };
        }, {});
    }

    stepHasDueDateExtension(step: IStep): boolean {
        const hasDueDateExtension = step.extensions.find((ext) => ext?.type === DUE_DATE_EXTENSION_TYPE);
        return !!hasDueDateExtension;
    }

    private getAssigneeControl(step: IStep, isRequired: boolean): StepDataForm {
        const assigneeData = AssigneeExtensionHelpers.getAssigneeData(step?.extensions);
        const validators = isRequired ? [Validators.required] : null;
        const control = assigneeData ? new FormControl(assigneeData?.assignees || [], validators) : null;
        return control ? { assignees: control } : {};
    }

    private getDueDateControl(step: IStep, dueDates: IWorkflowDueDate[]): StepDataForm {
        const stepDueDate = dueDates.find((stepDate) => stepDate.step.id === step.id);
        const control = stepDueDate ? new FormControl(stepDueDate.dueDate, [Validators.required]) : null;
        return control ? { dateTime: control } : {};
    }
}
