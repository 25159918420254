<centered-modal-layout
    [loading]="!!loader.counter"
    [disableSave]="!form.valid || !!loader.counter || !!tableLoader.counter"
    cancelButtonTitle="Close"
    saveButtonTitle="Create"
    (save)="create()"
    (close)="dialogRef.close()"
>
    <div modal-header>
        <request-header
            title="Create bill approval"
            [isComplete]="false"
            [completedStatusLabel]="RequestStatuses.Pending"
            [accountName]="thread?.account?.label || ''"
        ></request-header>

        <banner-relevant-memories
            *ngIf="(enableMemories$ | async) && thread?.id"
            [threadId]="thread?.id"
            [accountId]="thread?.account?.id"
        ></banner-relevant-memories>
    </div>

    <div modal-content>
        <div [formGroup]="form">
            <div class="request-common-group">
                <div class="request-common-label request-common-group-header">Note or message</div>
                <request-view-reply [cardDescription]="form.controls.cardDescription"></request-view-reply>
            </div>

            <div class="create-bill-approval-controls">
                <div class="create-bill-approval-controls-item">
                    <planned-payment-date formControlName="plannedPaymentDate"></planned-payment-date>

                    <div
                        class="fx-form-validation brand-danger"
                        *ngIf="!form.controls.plannedPaymentDate.valid && !form.controls.plannedPaymentDate.value"
                    >
                        Planned payment date is required
                    </div>
                </div>
                <div class="create-bill-approval-controls-item">
                    <due-date-range formControlName="range"></due-date-range>

                    <div
                        class="fx-form-validation brand-danger"
                        *ngIf="!form.controls.range.valid && !form.controls.range.value"
                    >
                        Start date and end date is required
                    </div>
                </div>
            </div>

            <loader-icon
                *ngIf="!!tableLoader.counter"
                color="light"
                [static]="true"
            ></loader-icon>
            <ng-container *ngIf="!tableLoader.counter">
                <ng-container *ngIf="invoices$ | async; let invoices">
                    <bill-approval-list
                        [invoices]="invoices$ | async"
                        [allowEdit]="true"
                        [allowEditComments]="true"
                        [form]="invoiceItemsForm"
                        (updateTable)="handleUpdate($event)"
                    ></bill-approval-list>
                    <empty-state
                        *ngIf="!invoices.length"
                        title="You're all up to date!"
                        htmlText="There are no bills due for the selected range"
                    >
                    </empty-state>
                </ng-container>
            </ng-container>
        </div>
    </div>
</centered-modal-layout>
