<loader-icon
    *ngIf="loader.counter"
    color="light"
></loader-icon>
<div
    class="notification-settings"
    *ngIf="!loader.counter && userSettings"
>
    <h3 class="notification-settings-title">Notification preferences</h3>
    <h4 class="notification-settings-headline">Recent activity</h4>
    <ng-container>
        <div class="notification-preference">
            <div class="notification-preference-title">Email summary of all activity since your last visit</div>
            <div class="notification-preference-content">
                <div class="notification-preference-content-select">
                    <p-selectButton
                        [(ngModel)]="frequency"
                        [disabled]="
                            !(currentUserRole$ | async | permissions : 'SaveAllNotificationSettings' | async) &&
                            !(isCurrentUserOwnSettings$ | async)
                        "
                        [options]="preferenceLabels | keyvalue : originalOrder"
                        optionValue="value"
                        (onChange)="changePreferenceMethodFrequency($event)"
                    >
                        <ng-template let-item>
                            <i
                                class="pi pi-check p-button-icon-left"
                                *ngIf="frequency === item.value"
                            ></i
                            >{{ item.key }}
                        </ng-template>
                    </p-selectButton>
                </div>
                <div class="notification-preference-content-message">
                    <i
                        placement="top"
                        class="las la-exclamation-circle info-icon"
                    ></i>
                    <span *ngIf="userSettings.preference.digest.frequency === frequencies.Frequent"
                        >May be multiple emails per day.</span
                    >
                    <span *ngIf="userSettings.preference.digest.frequency === frequencies.Daily"
                        >One email per day delivered at 9am.</span
                    >
                    <span *ngIf="userSettings.preference.digest.frequency === frequencies.Weekly"
                        >One email per week delivered on Monday at 9am.</span
                    >
                </div>
            </div>
        </div>
        <div class="notification-settings-actions">
            <button
                class="fx-btn fx-btn--primary notification-settings-actions-button"
                [disabled]="!!loader.counter || userSettings.preference.digest.frequency === frequency"
                type="submit"
                (click)="saveChanges()"
            >
                Save Changes
            </button>
        </div>
    </ng-container>
</div>
