import { IParticipant, IWorkflowConfigurationSteps, ParticipantType, Role } from "@visoryplatform/threads";
import { Observable, combineLatest, forkJoin, of } from "rxjs";
import { defaultIfEmpty, map } from "rxjs/operators";

import { AdminService } from "../../admin/services/admin.service";
import { AppUser } from "../../findex-auth";
import { IParticipantUi } from "../interfaces/IParticipantUi";
import { Injectable } from "@angular/core";
import { UserProfileService } from "../../user-profile/services/user-profile.service";
import { WorkflowSteps } from "@visoryplatform/workflow-core";
import { WorkflowStepsService } from "./workflow/workflow-steps.service";

@Injectable({
    providedIn: "root",
})
export class ParticipantService {
    constructor(
        private adminService: AdminService,
        private userProfileService: UserProfileService,
        private workflowStepService: WorkflowStepsService,
    ) {}

    searchAllUsers(searchTerm: string | null): Observable<IParticipant[]> {
        if (!searchTerm || searchTerm.length <= 2) {
            return of(null);
        }

        const users$ = this.adminService.searchClients(searchTerm);

        if (!users$) {
            return of(null);
        }

        return users$.pipe(
            map((users: AppUser[]) => {
                return users.map((user) => this.mapUserToParticipant(user));
            }),
        );
    }

    mapUserToParticipant(user: AppUser): IParticipant {
        return {
            id: user.id,
            role: user.id.startsWith("azuread-") ? Role.Staff : Role.Client,
            type: ParticipantType.User,
            profile: {
                name: user.name,
                emailAddress: user.details.emailAddress,
            },
        };
    }

    getClonedParticipants(
        defaultThreadParticipants: IParticipant[],
        assignees: string[],
        workflowObservers?: string[],
        tokenAssignees?: string[],
    ): Observable<IParticipantUi[]> {
        const defaultParticipants = this.getDefaultParticipants(defaultThreadParticipants);
        const defaultParticipantsUi = this.mapParticipantsToUi(defaultParticipants, false);
        const assigneeUi$ = this.getAssigneeUi(assignees);
        const observerUi$ = this.getObserverUi(workflowObservers, assignees);
        const tokenAssigneeUi$ = this.getAssigneeUi(tokenAssignees);

        return this.combineAndMapParticipantsUi(defaultParticipantsUi, assigneeUi$, observerUi$, tokenAssigneeUi$);
    }

    getUserProfiles(ids: string[]): Observable<AppUser[]> {
        if (!ids?.length) {
            return of([]);
        }
        const usersObservableArray = ids.map((ids) => this.userProfileService.getUserProfile(ids));
        return forkJoin(usersObservableArray);
    }

    getParticipantUi(participant: IParticipant, isAssignee: boolean): IParticipantUi {
        return {
            ...participant,
            isAssignee,
        };
    }

    mapUsersToParticipantsUi(assigneeUsers: AppUser[], isAssignee: boolean): IParticipantUi[] {
        const participants = this.mapUsersToParticipants(assigneeUsers);
        return participants.map((participant) => this.getParticipantUi(participant, isAssignee));
    }

    mapUsersToParticipants(users: AppUser[]): IParticipant[] {
        return users.filter((user) => !!user).map((user) => this.mapUserToParticipant(user));
    }

    getThreadAssignees(workflowSteps: WorkflowSteps): string[] {
        const workflowStepsArray = Object.entries(workflowSteps);
        const assigneeIds = workflowStepsArray
            .map(([, step]) => this.workflowStepService.getStepAssignees(step))
            .flat();

        const uniqueAssignees = new Set(assigneeIds);
        return [...uniqueAssignees];
    }

    getWorkflowConfigurationObservers(observers: string[] | null): string[] {
        if (!observers?.length) {
            return [];
        }

        const uniqueObservers = new Set(observers);
        return [...uniqueObservers];
    }

    getWorkflowConfigurationAssignees(workflowSteps: IWorkflowConfigurationSteps | null): string[] {
        if (!workflowSteps) {
            return [];
        }

        const workflowConfigurationStepsArray = Object.entries(workflowSteps);

        const assigneeIds = workflowConfigurationStepsArray
            .filter(([, step]) => !!step?.assignees?.length)
            .map(([, step]) => step.assignees)
            .flat();

        const uniqueAssignees = new Set(assigneeIds);
        return [...uniqueAssignees];
    }

    isUserThreadParticipant(participants: IParticipant[], userId: string): boolean {
        return participants.some((participant) => participant.id === userId);
    }

    private getDefaultParticipants(participants: IParticipant[]): IParticipant[] {
        return participants || [];
    }

    private mapParticipantsToUi(participants: IParticipant[], isAssignee: boolean): IParticipantUi[] {
        return participants.map((participant) => this.getParticipantUi(participant, isAssignee));
    }

    private getAssigneeUi(assignees?: string[]): Observable<IParticipantUi[]> {
        if (!assignees?.length) {
            return of([]);
        }

        const userProfiles$ = assignees.map((assignee) => this.userProfileService.getUserProfile(assignee));

        return forkJoin(userProfiles$).pipe(
            defaultIfEmpty([]),
            map((assigneeUsers: AppUser[]) => this.mapUsersToParticipantsUi(assigneeUsers, true)),
        );
    }

    private getObserverUi(
        workflowObservers: string[] | undefined,
        assignees: string[],
    ): Observable<IParticipantUi[]> | Observable<never> {
        const observerProfiles$ = this.filterObserversByExistingAssignees(workflowObservers, assignees) || [];

        return forkJoin(observerProfiles$).pipe(
            defaultIfEmpty([]),
            map((observerUsers: AppUser[]) => this.mapUsersToParticipantsUi(observerUsers, false)),
        );
    }

    private combineAndMapParticipantsUi(
        defaultParticipantsUi: IParticipantUi[],
        assigneeUi$: Observable<IParticipantUi[]>,
        observerUi$: Observable<IParticipantUi[]>,
        tokenAssigneesUi$: Observable<IParticipantUi[]>,
    ): Observable<IParticipantUi[]> {
        return combineLatest([assigneeUi$, observerUi$, tokenAssigneesUi$]).pipe(
            map(([assigneeParticipantsUi, observerUi, tokenAssigneesUi]) =>
                this.getCombinedParticipantsUi(defaultParticipantsUi, [
                    ...assigneeParticipantsUi,
                    ...observerUi,
                    ...tokenAssigneesUi,
                ]),
            ),
        );
    }

    private filterObserversByExistingAssignees(
        workflowObservers: string[],
        assignees: string[],
    ): Observable<AppUser>[] {
        return workflowObservers
            ?.filter((observer) => !assignees.includes(observer))
            .map((observer) => this.userProfileService.getUserProfile(observer));
    }

    private getCombinedParticipantsUi(
        defaultParticipants: IParticipantUi[],
        assigneeParticipants: IParticipantUi[],
    ): IParticipantUi[] {
        const uniqueParticipants = [...assigneeParticipants];

        for (const participant of defaultParticipants) {
            const index = uniqueParticipants.findIndex((addedParticipant) => addedParticipant.id === participant.id);
            if (index < 0) {
                uniqueParticipants.push(participant);
            }
        }

        return uniqueParticipants;
    }
}
