import { Pipe, PipeTransform } from "@angular/core";
import { IParticipant } from "@visoryplatform/threads";
import { MentionableUser } from "../components/quill-wrapper/mentionable-user";
import { ProfilePictureService } from "../services/profile-picture.service";

@Pipe({ name: "participantsToMention" })
export class ParticipantsToMentionPipe implements PipeTransform {
    constructor(private profilePictureService: ProfilePictureService) {}
    transform(participants: IParticipant[]): MentionableUser[] {
        return participants
            .map((participant) => ({
                id: participant.id,
                value: participant.name || participant.profile?.name,
                title: participant.profile?.title,
                avatarUrl: this.profilePictureService.getUrl(participant.id),
            }))
            .sort(this.sortByName);
    }

    private sortByName(a: MentionableUser, b: MentionableUser): number {
        if (!a.value) {
            return 1;
        }
        if (!b.value) {
            return -1;
        }
        return a.value.localeCompare(b.value);
    }
}
