import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FxUiModule } from "@visoryplatform/fx-ui";

import { AnalyticsModule } from "../analytics";
import { FindexUiModule } from "../findex-ui";
import { SharedPipesModule } from "../shared/pipes/shared-pipes.module";
import { RouterModule } from "@angular/router";
import { UiComponentsModule } from "../ui-components";
import { PortalUiModule } from "@visoryplatform/portal-ui";
import { CreateCustomerRequestModalComponent } from "./components/create-customer-request-modal/create-customer-request-modal.component";
import { CreateSuccessComponent } from "./components/create-success/create-success.component";
import { CustomerRequestGlobalButtonComponent } from "./components/customer-request-global-button/customer-request-global-button.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UiLayoutsModule } from "../ui-layouts/ui-layouts.module";
import { ThreadsUiModule } from "../threads-ui";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FxUiModule,
        SharedPipesModule,
        AnalyticsModule,
        FindexUiModule,
        UiComponentsModule,
        PortalUiModule,
        ReactiveFormsModule.withConfig({ callSetDisabledState: "whenDisabledForLegacyCode" }),
        FormsModule,
        UiLayoutsModule,
        ThreadsUiModule,
    ],
    declarations: [CreateCustomerRequestModalComponent, CreateSuccessComponent, CustomerRequestGlobalButtonComponent],
    exports: [CreateCustomerRequestModalComponent, CustomerRequestGlobalButtonComponent],
    providers: [],
})
export class CustomerRequestModule {}
