import { Component } from "@angular/core";

@Component({
    selector: "create-workflow-modal",
    templateUrl: "./create-workflow-modal.component.html",
    styleUrls: ["./create-workflow-modal.component.scss"],
})
export class CreateWorkflowModalComponent {
    constructor() {
        console.log("CreateWorkflowModalComponent created");
    }
}
