<div
    [ngClass]="{
        'list-item-container--completed': entry.isCompleted,
        'list-item-container--not-ready': !(workflow.currentStepId === entry.step.id || entry.isCompleted),
        'list-item-container--last-milestone': isLastMilestone
    }"
    class="list-item-container"
>
    <div
        [ngClass]="{
            'list-item-container-border--active-step': workflow.currentStepId === entry.step.id,
            'list-item-container-border--not-ready': !(workflow.currentStepId === entry.step.id || entry.isCompleted)
        }"
        class="list-item-container-border"
    ></div>
    <div class="list-item">
        <fx-checkbox
            #checkbox
            [checked]="entry.isCompleted || workflow.currentStepId === SystemStepId.End"
            [disabled]="true"
            [ngClass]="{
                'list-item-checkbox--active': workflow.currentStepId === entry.step.id,
                'list-item-checkbox--completed': entry.isCompleted,
                'list-item-checkbox--not-ready': !(workflow.currentStepId === entry.step.id || entry.isCompleted),
                'list-item-checkbox--closed': workflow.currentStepId === SystemStepId.End
            }"
        >
            <div
                [ngClass]="{
                    'list-item-checkbox-grey': workflow.currentStepId !== entry.step.id,
                    'list-item-checkbox-number-double-digit': index + 1 >= 10,
                    'list-item-checkbox-number': true
                }"
            >
                {{ workflow.currentStepId !== SystemStepId.End ? index + 1 : null }}
            </div>
        </fx-checkbox>

        <div class="list-item-body">
            <div>
                <div
                    [ngClass]="{
                        'active-step': workflow.currentStepId === entry.step.id,
                        'not-ready': !(workflow.currentStepId === entry.step.id || entry.isCompleted)
                    }"
                    class="list-item-body-title"
                >
                    <span class="list-item-body-title-label">{{ entry.step.label }} </span>
                    <fx-badge
                        *ngIf="
                            (role | permissions : 'UpdateWorkflowTimeEstimates' | async) &&
                            entry.isOverdue &&
                            !entry.isCompleted
                        "
                        [tuiHint]="text.DuePassed"
                        [label]="text.Overdue"
                        tuiHintDirection="top"
                        type="error"
                    >
                    </fx-badge>
                </div>

                <div class="list-item-body-details">
                    <div
                        *ngIf="role | permissions : 'UpdateInternalWorkflow' | async"
                        class="list-item-body-details-timestamp"
                    >
                        <div
                            *ngIf="(entry.dueDate || entry.isCompleted) && hasDueDates"
                            class="list-item-body-details-timestamp-description"
                            [ngClass]="{
                                'active-step': workflow.currentStepId === entry.step.id
                            }"
                        >
                            <span *ngIf="entry.isCompleted && entry.completedDate; else due">
                                {{ text.Completed + (entry.completedDate | date : "ccc, d LLL yy h:mma") }}
                            </span>
                            <ng-template #due>
                                <div *ngIf="entry?.dueDate">
                                    <span>{{ text.Due }}</span>
                                    <strong>
                                        <span class="list-item-body-details-timestamp-description-value">
                                            {{ entry.dueDate | date : "ccc, d LLL yy h:mma" }}
                                        </span>
                                    </strong>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
                <div
                    class="work-time-pill"
                    tuiHint="The amount of time this task should take to complete."
                    tuiHintDirection="right"
                    *ngIf="workTime$ | async as workTime"
                >
                    <i class="las la-clock work-time-pill-icon"></i> {{ workTime | duration }}
                </div>

                <div
                    *ngIf="workflow.currentStepId === entry.step.id"
                    class="action-buttons"
                >
                    <ng-container
                        *ngFor="let action of entry.step.actions; trackBy: 'id' | trackProperty; let i = index"
                    >
                        <button
                            *ngIf="!action.hidden && action.id !== SystemStepId.Cancelled"
                            (click)="selectStepAt(action)"
                            [ngClass]="{ 'fx-btn--primary': i === 0, 'fx-btn--secondary': i !== 0 }"
                            class="fx-btn fx-btn--small action-button"
                            [disabled]="!(entry.step | enableStepAction)"
                        >
                            {{ action.label }} &nbsp;
                        </button>
                    </ng-container>
                </div>
            </div>

            <div class="list-item-body-right">
                <fx-avatar
                    *ngFor="let assignee of assignees"
                    [name]="assignee | userToName | async"
                    [image]="assignee | profilePicture | async"
                    size="medium"
                ></fx-avatar>
            </div>
        </div>
    </div>
</div>
