<ng-container *ngIf="card$ | async; let card">
    <ng-container *ngIf="state$ | async; let state">
        <ng-container
            *ngIf="{
                isCompleted: state?.isCompleted
            } as cardStatus"
        >
            <ng-container *ngIf="userId$ | async; let userId">
                <thread-card
                    [title]=""
                    [thread]="thread$ | async"
                    [card]="card$ | async"
                    [replies]="replies$ | async"
                    [invertTheme]="userId === card.createdBy"
                    [edited]="card.status === cardStatuses.Edited"
                    [loading]="!!loader.counter"
                    [inCardReplies]="false"
                    [isInternal]="card.isInternal"
                    (openRepliesInModal)="openRequestModal(!!state?.attachments?.fileId, true)"
                >
                    <div
                        class="request-panel"
                        (click)="openRequestModal(!!state?.attachments?.fileId, false)"
                        [ngClass]="{ 'request-panel--active': isThreadActive$ | async }"
                    >
                        <div class="request-panel-header">
                            <h4 class="request-heading">{{ state?.title }}</h4>
                            <fx-badge
                                [label]="cardStatus.isCompleted ? 'Approved' : 'Pending'"
                                [type]="cardStatus.isCompleted ? 'success' : 'default'"
                            ></fx-badge>
                        </div>

                        <div class="request-info">
                            <div class="request-info-progress">
                                <progress-bar [progress]="actionedPercentage"></progress-bar>
                                <span class="request-info-actioned"
                                    >{{ actionedRequestItems }} / {{ state?.requestItems?.length }} actioned</span
                                >
                            </div>

                            <div class="request-info-icons">
                                <i class="las la-comment-alt"></i>
                                <span>{{ (replies$ | async)?.length || 0 }}</span>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="isThreadActive$ | async">
                        <button
                            *ngIf="!(canRespondToRequest$ | async)"
                            class="request-button fx-btn fx-btn--primary"
                            [analyticsClick]="gaEvents.PAYRUN_VIEW_COMPLETED"
                            (click)="action(VAULT_ACTION_REQUEST_VIEW_RESPONSE)"
                            [disabled]="!state?.attachments?.fileId"
                        >
                            View Report
                        </button>
                        <button
                            *ngIf="canRespondToRequest$ | async"
                            class="request-button fx-btn fx-btn--primary"
                            [analyticsClick]="gaEvents.PAYRUN_REVIEW_PENDING"
                            (click)="action(VAULT_ACTION_REQUEST_RESPONSE)"
                            [disabled]="!state?.attachments?.fileId"
                        >
                            Review
                        </button>
                    </ng-container>
                    <div
                        card-actions
                        *ngIf="allowEdit"
                    >
                        <fx-context-menu
                            #menu
                            [gutter]="false"
                            *ngIf="canEditCard$ | async"
                        >
                            <a (click)="action(VAULT_ACTION_REQUEST_EDIT_RESPONSE)">Edit</a>
                            <a (click)="deleteCard()">Delete</a>
                        </fx-context-menu>
                    </div>
                    <div card-loader>
                        <loader-icon color="light"></loader-icon>
                    </div>
                    <div
                        class="thread-card-error"
                        *ngIf="errorMessage"
                    >
                        {{ errorMessage }}
                    </div>
                </thread-card>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-container>
