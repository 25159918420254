import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AnalyticsModule } from "projects/portal-modules/src/lib/analytics";
import { FindexAuthModule } from "projects/portal-modules/src/lib/findex-auth";
import { FindexUiModule } from "projects/portal-modules/src/lib/findex-ui";
import { SharedModule } from "projects/portal-modules/src/lib/shared";
import { ThreadsUiModule } from "projects/portal-modules/src/lib/threads-ui";
import { VaultCardItemComponent } from "./components/vault-card-item/vault-card-item.component";
import { VaultRequestCardComponent } from "../vault-request-card/vault-request-card.component";
import { VaultCardComponent } from "./components/vault-card/vault-card.component";
import { VaultAttachmentsComponent } from "./components/vault-attachments/vault-attachments.component";
import { FileInputModule } from "../file-input/file-input.module";
import { InlineEditModule } from "../inline-edit/inline-edit.module";
import { HttpClientModule } from "@angular/common/http";
import { VaultCardApiService } from "./services/vault-card-api.service";
import { VaultCardService } from "./services/vault-card.service";
import { SharedPipesModule } from "../../../../portal-modules/src/lib/shared/pipes/shared-pipes.module";

@NgModule({
    declarations: [VaultCardItemComponent, VaultCardComponent, VaultAttachmentsComponent, VaultRequestCardComponent],
    exports: [VaultCardComponent, VaultAttachmentsComponent],
    providers: [VaultCardApiService, VaultCardService],
    imports: [
        CommonModule,
        FindexUiModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule.withConfig({ callSetDisabledState: "whenDisabledForLegacyCode" }),
        FindexAuthModule,
        HttpClientModule,
        ThreadsUiModule,
        AnalyticsModule,
        SharedModule,
        FileInputModule,
        InlineEditModule,
        SharedPipesModule,
    ],
})
export class VaultCardModule {}
