<div class="new-thread-type">
    <select-service
        [formControl]="serviceControl"
        [workflowDesignTypes]="workflowDesignTypes"
        [showWorkflowConfigurationSelectors]="showWorkflowConfigurationSelectors"
    >
    </select-service>

    <select-workflow-form
        [formControl]="workflowDesignControl"
        [workflowConfigurations]="configurations$ | async | orderBy : ['label', sortOption.ASC]"
        [workflowDesigns]="workflowDesigns$ | async | orderBy : ['label', sortOption.ASC]"
    ></select-workflow-form>

    <select-workflow-token
        [formControl]="workflowTokenControl"
        [selectedDesignId]="workflowDesignControl.value?.designId"
        [selectedConfigurationId]="workflowDesignControl.value?.workflowConfigurationId"
        [workflowConfigurations]="configurations$ | async"
        [workflowDesignTypes]="workflowDesignTypes"
    ></select-workflow-token>
</div>
