import { Injectable } from "@angular/core";
import { ICardTaskDetail, IThread } from "@visoryplatform/threads";
import { ThreadCardService } from "../../services/thread-card.service";
import { TaskNotificationsService } from "../../../notifications/services/task-notifications.service";
import { CalendarService } from "projects/default-plugins/calendar/services/calendar.service";
import { PortalService } from "../../../shared/services/portal.service";

@Injectable()
export class CloseThreadService {
    constructor(
        private cardsService: ThreadCardService,
        private taskNotificationService: TaskNotificationsService,
        private calendarService: CalendarService,
        private portalService: PortalService,
    ) {}

    async getPendingTasks(threadId: string): Promise<ICardTaskDetail[]> {
        return this.taskNotificationService.getThreadCardTasks(threadId).toPromise();
    }

    async threadHasUpcomingMeetings(threadId: string): Promise<boolean> {
        const allUpcomingMeetings = await this.calendarService.getUpcomingMeetings().toPromise();
        return allUpcomingMeetings.some((meeting) => meeting.threadId === threadId);
    }

    async findThreadsToMigrateMeetings(thread: IThread, _userId: string): Promise<IThread[]> {
        const requiredParticipantIds = thread.participants.map((participant) => participant.id);
        const threadList = await this.portalService.getAllThreads({ status: "active" }).toPromise();
        return threadList
            .filter((item) => item.id !== thread.id)
            .filter((item) => {
                const participantIds = item.participants.map((participant) => participant.id);
                return requiredParticipantIds.every((requiredParticipantId) =>
                    participantIds.some((participantId) => requiredParticipantId === participantId),
                );
            });
    }

    async migrateCalendarCards(threadId: string, targetThreadId: string): Promise<void> {
        return this.cardsService.migrateCalendarCards(threadId, targetThreadId).toPromise();
    }
}
