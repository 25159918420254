import { Component, Input, Output, EventEmitter, Optional, Self } from "@angular/core";
import { ControlContainer, ControlValueAccessor, FormGroupDirective, NgControl } from "@angular/forms";
import { environmentCommon } from "../../../environment/environment.common";

export const NOOP_VALUE_ACCESSOR: ControlValueAccessor = {
    writeValue(): void {},
    registerOnChange(): void {},
    registerOnTouched(): void {},
};

@Component({
    selector: "quill-form-control",
    templateUrl: "./quill-form-control.component.html",
    styleUrls: ["./quill-form-control.component.scss"],
    viewProviders: [
        {
            provide: ControlContainer,
            useExisting: FormGroupDirective,
        },
    ],
})
export class QuillEditorUntypedFormControlComponent {
    @Output() error = new EventEmitter<boolean>();

    @Input() placeholder: string;
    @Input() inline = false;
    @Input() readOnly = false;

    toolbar = false;
    withToolbar = environmentCommon.quillConfig.toolbarState.withToolbar;
    quillStyles = environmentCommon.quillConfig.styling;
    characterError: boolean;

    constructor(@Self() @Optional() public ngControl: NgControl) {
        if (this.ngControl) {
            this.ngControl.valueAccessor = NOOP_VALUE_ACCESSOR;
        }
    }

    toggleToolbar() {
        if (!this.readOnly) {
            this.toolbar = !this.toolbar;
        }
    }

    autoFocus(quill) {
        if (!this.readOnly) {
            quill.focus();
        }
    }

    validateInput(value: string) {
        const val = value?.length > 200000;
        this.characterError = val;
        this.error.emit(val);
    }
}
