<div class="replace-user-container">
    <loader-icon
        *ngIf="loader.counter"
        color="light"
    ></loader-icon>

    <h2 class="replace-user-control">Bulk change</h2>

    <form [formGroup]="form">
        <add-participant
            class="replace-user-control"
            label="Replace with user"
            formControlName="newUsers"
        ></add-participant>

        <fx-checkbox
            class="replace-user-control"
            formControlName="updateActiveWorkflows"
            >Replace assignee in active workflows</fx-checkbox
        >

        <fx-checkbox
            class="replace-user-control"
            formControlName="updateConfigurations"
            >Replace assignee in workflow configurations</fx-checkbox
        >

        <fx-checkbox
            *ngIf="form.value?.updateActiveWorkflows"
            class="replace-user-control"
            formControlName="keepAsParticipant"
            >Keep as participant</fx-checkbox
        >
        <button
            class="fx-btn fx-btn--primary replace-user-confirm-btn"
            (click)="confirmBulkAndReplace()"
            [disabled]="!form.valid"
        >
            Save
        </button>
    </form>

    <ng-container *ngIf="form.value?.updateActiveWorkflows">
        <bulk-replace-user-workflows
            [formControl]="form.controls.excludeThreadIds"
            [newUserIds]="form.value.newUsers"
        ></bulk-replace-user-workflows>
    </ng-container>
    <ng-container *ngIf="form.value?.updateConfigurations">
        <bulk-replace-user-configurations
            [formControl]="form.controls.excludeConfigurationIds"
            [newUserIds]="form.value.newUsers"
        ></bulk-replace-user-configurations>
    </ng-container>
</div>
