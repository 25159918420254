<div class="stepper">
    <stepper-breadcrumbs
        [steps]="steps"
        [selectedIndex]="selectedIndex"
    ></stepper-breadcrumbs>

    <div class="stepper-content">
        <ng-container [ngTemplateOutlet]="selected.content"> </ng-container>
    </div>

    <stepper-footer
        *ngIf="!steps.get(selectedIndex)?.hideFooter"
        [disabled]="disabled"
        [disabledNext]="disabledNext"
        [endStepLabel]="endStepLabel"
        [selectedIndex]="selectedIndex"
        [totalSteps]="steps.length"
        [hideNavigationButtons]="steps.get(selectedIndex)?.hideNavigationButtons"
        [backStepLabel]="steps.get(selectedIndex)?.backStepLabel"
        [nextStepLabel]="steps.get(selectedIndex)?.nextStepLabel"
        (completed)="completed.emit($event)"
        (clickNext)="handleClickNext($event)"
        (cancel)="cancel.emit($event)"
    ></stepper-footer>
</div>
