<div
    *ngIf="!hideNavigationButtons; else cancelFooter"
    class="stepper-footer"
>
    <div class="stepper-footer-button">
        <button
            class="stepper-footer-buttons-back"
            [disabled]="disabled || selectedIndex === 0"
            cdkStepperPrevious
        >
            <i class="las la-angle-left stepper-icon"></i>
            {{ backStepLabel }}
        </button>
    </div>

    <progress-bar
        class="stepper-progress-bar"
        [progress]="((selectedIndex + 1) / totalSteps) * 100"
    ></progress-bar>

    <div class="stepper-footer-button">
        <button
            class="fx-btn fx-btn--primary stepper-buttons-next"
            [disabled]="disabled || disabledNext || (stepper.linear && !stepper.selected?.completed)"
            (click)="handleNext(selectedIndex, totalSteps)"
        >
            {{ selectedIndex + 1 === totalSteps && endStepLabel ? endStepLabel : nextStepLabel }}
            <i class="las la-angle-right stepper-icon"></i>
        </button>
    </div>
</div>

<ng-template #cancelFooter>
    <div class="stepper-footer">
        <div class="stepper-footer-button">
            <button
                class="fx-btn--white stepper-footer-cancel"
                (click)="cancel.emit()"
            >
                Cancel
            </button>
        </div>
    </div>
</ng-template>
