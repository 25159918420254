import { Component, OnDestroy, Inject, OnInit } from "@angular/core";
import { Subscription, Observable, combineLatest, EMPTY, concat } from "rxjs";
import { IThreadCard } from "@visoryplatform/threads/dist/interfaces/IThreadCard";
import { Role, IThread, CardReply, CardStatus, VideoCallAction, IVcCardState } from "@visoryplatform/threads";
import { catchError, filter, map, shareReplay, take } from "rxjs/operators";
import { THREAD_CARD_RESOURCES, CardResources } from "projects/portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { AppUser, AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { ThreadCardService } from "projects/portal-modules/src/lib/threads-ui/services/thread-card.service";
import { ActionableCardComponent } from "projects/portal-modules/src/lib/shared/components/actionable-card/actionable-card.component";
import { ISlot } from "projects/default-plugins/calendar/services/calendar.service";
import { ILibrary, TaskAction } from "projects/portal-modules/src/lib/plugins";
import { ENVIRONMENT, TASK_ACTION_LIBRARY } from "src/app/injection-token";
import { TaskActionService } from "projects/portal-modules/src/lib/shared/components/actionable-card/task-action.service";
import { VideoCallService } from "../../services/video-call.service";
import { ThreadsService } from "projects/portal-modules/src/lib/threads-ui/services/threads.service";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";

export interface ActionReturnType {
    isConnectionActive: boolean;
}

@Component({
    selector: "video-call-card",
    templateUrl: "./video-call-card.component.html",
    styleUrls: ["video-call-card.component.scss"],
})
export class VideoCallCardComponent extends ActionableCardComponent<ActionReturnType> implements OnDestroy, OnInit {
    thread$: Observable<IThread>;
    card$: Observable<IThreadCard>;
    user$: Observable<AppUser>;
    replies$: Observable<CardReply[]>;
    state$: Observable<IVcCardState>;
    role: Role;
    loader = new Loader();
    errorMessage: string;
    CardStatus = CardStatus;
    showJoinCall$: Observable<boolean>;
    showEndCall$: Observable<boolean>;
    cardStatuses = CardStatus;

    private readonly initialVideoAppUrl = "about:blank";
    private endSessionSub: Subscription;
    private deleteCardSub: Subscription;

    constructor(
        @Inject(THREAD_CARD_RESOURCES) public cardResources: CardResources<IVcCardState>,
        @Inject(TASK_ACTION_LIBRARY) protected taskActions: ILibrary<TaskAction<ISlot>>,
        private authService: AuthService,
        private cardService: ThreadCardService,
        protected taskActionService: TaskActionService,
        private videoCallService: VideoCallService,
        private threadsService: ThreadsService,
        private permissionService: PermissionService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {
        super(cardResources, taskActionService);
    }

    ngOnInit(): void {
        const { thread$, card$, role, replies$: replies, state$ } = this.cardResources;

        this.thread$ = thread$;
        this.card$ = card$;
        this.role = role;
        this.replies$ = replies;
        this.state$ = state$;
        this.user$ = this.authService.getUser().pipe(filter((user) => !!user));

        const isThreadActive$ = this.thread$.pipe(
            map((thread) => this.threadsService.isThreadActive(thread)),
            shareReplay(1),
        );
        const joinVideoPermission$ = this.permissionService.checkPermissions(this.role, "JoinVideoCallSession");
        const endVideoPermission$ = this.permissionService.checkPermissions(this.role, "EndVideoCallSession");

        this.showJoinCall$ = combineLatest([isThreadActive$, joinVideoPermission$]).pipe(
            map(([isThreadActive, hasPermission]) => isThreadActive && hasPermission),
        );

        this.showEndCall$ = combineLatest([isThreadActive$, endVideoPermission$]).pipe(
            map(([isThreadActive, hasPermission]) => isThreadActive && hasPermission),
        );
    }

    ngOnDestroy(): void {
        this.endSessionSub?.unsubscribe();
        this.deleteCardSub?.unsubscribe();
    }

    joinSession(user: AppUser, state: IVcCardState): void {
        const { videoCallAppUrl } = this.environment;
        const { availWidth: width, availHeight: height } = screen;
        const videoCallWindow = window.open(
            this.initialVideoAppUrl,
            `video-call-window`,
            `popup=true,width=${width},height=${height}`,
        );

        this.createSignature(user, state)
            .pipe(take(1))
            .subscribe((signature) => {
                const urlParams = `signature=${signature}&username=${user.name}&sessionName=${state.sessionName}`;
                videoCallWindow.location.href = `${videoCallAppUrl}?${urlParams}`;
            });
    }

    endSession(threadId: string, cardId: string, state: IVcCardState): void {
        if (this.canTerminateSession(state)) {
            const endSession$ = this.videoCallService.endSession(state.sessionKey, threadId, cardId);
            const catchEndSessionError = catchError(() => {
                this.errorMessage = "Sorry, something went wrong";
                return EMPTY;
            });

            this.endSessionSub?.unsubscribe();
            this.endSessionSub = this.loader.wrap(endSession$.pipe(catchEndSessionError)).subscribe();
        }
    }

    // eslint-disable-next-line @typescript-eslint/require-await
    async actionCallback(actionId: string): Promise<void> {
        if (actionId === VideoCallAction.JOIN_CALL) {
            this.action(VideoCallAction.END_SESSION);
        }
    }

    deleteCard(threadId: string, cardId: string, state: IVcCardState): void {
        const endSession$ = this.videoCallService.endSession(state.sessionKey, threadId, cardId);
        const deleteCard$ = this.cardService.deleteCard(threadId, cardId);
        const endAndDeleteCard$ = this.canTerminateSession(state) ? concat(endSession$, deleteCard$) : deleteCard$;
        const catchDeleteError = catchError(() => {
            this.errorMessage = "Sorry, something went wrong";
            return EMPTY;
        });

        this.deleteCardSub?.unsubscribe();
        this.deleteCardSub = this.loader.wrap(endAndDeleteCard$.pipe(catchDeleteError)).subscribe();
    }

    private canTerminateSession(state: IVcCardState): boolean {
        return !state.isTerminated;
    }

    private createSignature(user: AppUser, state: IVcCardState): Observable<string> {
        return this.videoCallService.createSignature(
            this.cardResources.threadId,
            state.sessionName,
            user.id,
            state.sessionKey,
        );
    }
}
