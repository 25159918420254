<div class="request-common-group">
    <div class="request-common-label request-common-group-header">Note or message</div>
    <request-view-reply [cardDescription]="form.controls.cardDescription"></request-view-reply>
</div>

<ng-container *ngIf="statementMap">
    <div class="request-common-secondary-header">
        <h3>Transactions</h3>

        <div class="request-common-secondary-sub-header">Create queries for unreconciled transactions</div>

        <label
            class="date-range-label"
            id="dateRangeLabel"
        >
            Date range
        </label>
        <mat-form-field>
            <mat-date-range-input
                [rangePicker]="picker"
                [formGroup]="form.controls.range"
                [min]="minDate"
                [max]="maxDate"
            >
                <input
                    matStartDate
                    formControlName="startDate"
                    placeholder="Start"
                    aria-labelledby="dateRangeLabel"
                />
                <input
                    matEndDate
                    formControlName="endDate"
                    placeholder="End"
                    aria-labelledby="dateRangeLabel"
                />
            </mat-date-range-input>
            <mat-datepicker-toggle
                matIconSuffix
                [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
    </div>

    <ng-container *ngIf="!loader.counter && form.controls.transactions?.controls?.length">
        <transaction-list
            [transactions]="form.value.transactions"
            [editMode]="true"
            (remove)="removeControl($event.id)"
            (changeQuery)="changeQuery($event)"
        ></transaction-list>
    </ng-container>

    <empty-state
        *ngIf="!loader.counter && !form.controls.transactions?.controls?.length"
        title="You're all up to date!"
        htmlText="There are no unreconciled transactions for the selected range"
    >
    </empty-state>
</ng-container>

<loader-icon
    *ngIf="loader.counter"
    [static]="true"
    color="light"
></loader-icon>
