import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Subject } from "rxjs";
import { FormControl } from "@angular/forms";
import { Validators } from "@angular/forms";
import { IParticipant } from "@visoryplatform/threads";

@Component({
    selector: "app-card",
    templateUrl: "./card.component.html",
    styleUrls: ["./card.component.scss"],
})
export class CardComponent {
    @Input() name?: string;
    @Input() image?: string;
    @Input() participants?: IParticipant[];
    @Input() timestamp?: string | Date | number;
    @Input() title?: string;
    @Input() description?: string;
    @Input() lineCard: boolean;
    @Input() invertTheme?: boolean;
    @Input() conversation = true;
    @Input() align?: "left" | "right" | "centre";
    @Input() edit: Subject<boolean>;
    @Input() edited: boolean | Subject<boolean>;
    @Input() loading = false;
    @Input() showDescription = true;
    @Input() showFullDescription = false;
    @Input() isUnread: boolean;
    @Input() isInternal: boolean;
    @Input() useQuillHtml?: boolean;

    @Output() save = new EventEmitter();

    messageFormControl = new FormControl("", [Validators.required]);

    saveEdit($event: MouseEvent): void {
        $event.preventDefault();
        this.edit.next(false);
        this.save.emit(this.messageFormControl.value);
    }

    cancelEdit($event: MouseEvent): void {
        $event.preventDefault();
        this.edit.next(false);
    }
}
