import { Component, OnInit, Inject } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { IThread, IThreadCard, ITimeline, IWorkflowDesignType } from "@visoryplatform/threads";
import { SelectServiceControl } from "../../../../modules/create-thread/types/SelectDesignType";
import { combineLatest, EMPTY, Observable } from "rxjs";
import { WorkflowService } from "../../../../services/workflow/workflow.service";
import { expand, mergeMap, shareReplay, switchMap, take, toArray } from "rxjs/operators";
import { AccountsService } from "../../../../services/accounts.service";
import { Loader } from "../../../../../shared/services/loader";
import { PortalService } from "../../../../../shared/services/portal.service";
import { IReplyUi } from "../../../../interfaces/IReplyUi";
import { GA_EVENTS } from "../../../../../analytics/services/gtagAnalytics.service";
import { ThreadCardService } from "../../../../services/thread-card.service";

export interface IForwardMessageModalData {
    thread: IThread;
    card: IThreadCard;
    reply?: IReplyUi;
}

type IForwardMessageModalForm = {
    accountId: FormControl<string | null>;
    designControl: FormControl<Partial<SelectServiceControl>>;
    threadId: FormControl<string | null>;
};

@Component({
    selector: "forward-message-modal",
    templateUrl: "./forward-message-modal.component.html",
    styleUrls: ["./forward-message-modal.component.scss"],
})
export class ForwardMessageModalComponent implements OnInit {
    readonly gaEvents = GA_EVENTS;
    thread: IThread;
    card: IThreadCard;
    reply?: IReplyUi;
    loader = new Loader();

    designTypes$: Observable<IWorkflowDesignType[]>;
    threads$: Observable<ITimeline[]>;

    form = new FormGroup<IForwardMessageModalForm>({
        accountId: new FormControl<string | null>(null),
        designControl: new FormControl<Partial<SelectServiceControl>>({
            threadType: null,
        }),
        threadId: new FormControl<string | null>(null),
    });

    private readonly totalPerPage = 30;

    constructor(
        private dialogRef: MatDialogRef<ForwardMessageModalComponent>,
        private workflowService: WorkflowService,
        private accountsService: AccountsService,
        private portalService: PortalService,
        private threadCardService: ThreadCardService,
        @Inject(MAT_DIALOG_DATA) data: IForwardMessageModalData,
    ) {
        this.thread = data.thread;
        this.card = data.card;
        this.reply = data.reply;
    }

    ngOnInit(): void {
        this.designTypes$ = this.loader.wrap(this.workflowService.listDesignTypes()).pipe(shareReplay(1));

        const designControl$ = this.form.controls.designControl.valueChanges;
        const account$ = this.accountsService.getAccount(this.thread.accountId);

        this.threads$ = combineLatest([designControl$, account$]).pipe(
            switchMap(([designControl, account]) => {
                const threads$ = this.getAllThreads(designControl.threadType, account.id);
                return this.loader.wrap(threads$);
            }),
            shareReplay(1),
        );
    }

    close(): void {
        this.dialogRef.close();
    }

    forwardMessage(): void {
        const targetThreadId = this.form.value.threadId;
        const forwardMessage$ = this.threadCardService.forwardMessage(
            this.thread.accountId,
            this.thread.id,
            targetThreadId,
            this.card.id,
            this.reply?.id,
        );

        this.loader
            .wrap(forwardMessage$)
            .pipe(take(1))
            .subscribe(() => {
                this.dialogRef.close();
            });
    }

    private getAllThreads(threadType: string, accountId: string): Observable<ITimeline[]> {
        const filterQuery = { internalStepStatus: "active", account: accountId, type: threadType };
        const limit = this.totalPerPage;
        return this.portalService.getSearchThreadList("", limit, filterQuery).pipe(
            expand((page) => {
                if (page.next) {
                    return this.portalService.getSearchThreadList(page.next, limit, filterQuery);
                } else {
                    return EMPTY;
                }
            }),
            mergeMap((page) => page.result),
            toArray(),
        );
    }
}
