import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { CardReply, CardStatus, IThread, IThreadCard } from "@visoryplatform/threads";
import { ThreadCardRepliesComponent } from "projects/portal-modules/src/lib/threads-ui/components/thread-card-replies/thread-card-replies.component";
import { Observable } from "rxjs";

@Component({
    selector: "request-reply",
    templateUrl: "./request-reply.component.html",
    styleUrls: ["./request-reply.component.scss"],
})
export class RequestReplyComponent {
    @ViewChild("threadCardRepliesComponent") threadCardRepliesComponent: ThreadCardRepliesComponent;

    @Input() card: IThreadCard;
    @Input() focusReplyInput: boolean;
    @Input() replyMessage: FormControl<string>;
    @Input() replies: CardReply[];
    @Input() thread: IThread;
    @Input() userId$: Observable<string>;

    @Output() replySaved = new EventEmitter<void>();

    cardStatuses = CardStatus;
}
