import { Injectable } from "@angular/core";
import { VBadgeColors } from "@visoryplatform/portal-ui";

const ACCOUNTS_RECEIVABLE = "accounts receivable";
const BOOKKEEPING = "bookkeeping";
const ACCOUNTS_PAYABLE = "accounts payable";
const PEOPLE_AND_CULTURE = "people and culture";
const PAYROLL = "payroll";
const ACCOUNT_MANAGEMENT = "account management";

@Injectable({ providedIn: "root" })
export class ThreadTypeBadgeMappingService {
    getBadgeColor(threadType: string): VBadgeColors {
        const threadTypeLowerCase = threadType.toLowerCase();
        switch (threadTypeLowerCase) {
            case ACCOUNT_MANAGEMENT:
                return VBadgeColors.grey;
            case BOOKKEEPING:
                return VBadgeColors.blue;
            case ACCOUNTS_RECEIVABLE:
                return VBadgeColors.orange;
            case ACCOUNTS_PAYABLE:
                return VBadgeColors.violet;
            case PAYROLL:
                return VBadgeColors.yellow;
            case PEOPLE_AND_CULTURE:
                return VBadgeColors.green;
            default:
                return VBadgeColors.grey;
        }
    }
}
