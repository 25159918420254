export const CreateBillApprovalCardDescription = `
<p>Hi,</p><p> </p><p>Please review the list of bills below for the specified payment date and mark each as approved or declined.</p><p> </p><p>Approved bills will be paid in full in the upcoming supplier pay run, while any declined bills will be held and reappear in the next scheduled pay run workflow.</p><p> </p><p>If you'd prefer to make a part payment, please mark the bill as approved and reply here with the details. If you’re declining a bill but have a specific payment date in mind, just let us know in a reply.</p><p> </p><p>Thanks!</p>`;

export enum BillApprovalReviewModalData {
    Title = "Are you sure you want to send this bill approval for review?",
    Subhead = "Changes will be saved and sent to your Visory team for attention.",
}

export enum BillApprovalCompleteModalData {
    Title = "Are you sure you want to approve this bill approval?",
    Subhead = "No further changes can be made and we will process this bill approval.",
}
