import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
    CalendarAction,
    CardReply,
    ICalendarParticipant,
    IParticipant,
    IThread,
    IThreadCard,
    InternalRoles,
    Role,
    SubjectType,
    VideoChatAction,
} from "@visoryplatform/threads";
import { combineLatest, Observable, of, Subject, Subscription } from "rxjs";
import { filter, map, shareReplay, switchMap, take } from "rxjs/operators";
import { CardResources, THREAD_CARD_RESOURCES } from "projects/portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { CardStatus } from "@visoryplatform/threads/dist/enums/CardStatus";
import { ENVIRONMENT, TASK_ACTION_LIBRARY } from "src/app/injection-token";
import { ParticipantCache } from "projects/portal-modules/src/lib/threads-ui/services/participant-cache.service";
import { IAvatarContent } from "@visoryplatform/fx-ui";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import {
    CalendarMeetingRequestComponent,
    MeetingRequestModalData,
} from "../calendar-meeting-request/calendar-meeting-request.component";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { VcStateBuilder } from "projects/default-plugins/video-chat/services/vc-state-builder";
import { CalendarState, MeetingStatus } from "../../calendar-state.type";
import { ThreadCardService } from "projects/portal-modules/src/lib/threads-ui/services/thread-card.service";
import { CalendarService, ISlot } from "../../services/calendar.service";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import {
    CalendarInstanceData,
    CalendarInstanceModalComponent,
} from "../calendar-instance-modal/calendar-instance-modal.component";
import { environmentCommon } from "src/environments/environment";
import { ActionableCardComponent } from "projects/portal-modules/src/lib/shared/components/actionable-card/actionable-card.component";
import { TaskActionService } from "projects/portal-modules/src/lib/shared/components/actionable-card/task-action.service";
import { ILibrary, TaskAction } from "projects/portal-modules/src/lib/plugins";
import { VideoChatService } from "projects/default-plugins/video-chat/services/video-chat.service";
import { CalendarCardService } from "../../services/calendar-card.service";
import { IVCDetails } from "../../../video-chat/interfaces/IVCDetails";
import { IInvitation, IInvitee, IStaff } from "@visoryplatform/calendar-types";
import { ThreadsService } from "projects/portal-modules/src/lib/threads-ui/services/threads.service";
import { DialogService } from "projects/portal-modules/src/lib/shared/services/dialog.service";

const localLoader = (): Loader => {
    return new Loader();
};

@Component({
    selector: "calendar-card",
    templateUrl: "./calendar-card.component.html",
    styleUrls: ["./calendar-card.component.scss"],
    providers: [{ provide: Loader, useFactory: localLoader }],
})
export class CalendarCardComponent extends ActionableCardComponent<ISlot> implements OnInit, OnDestroy {
    static cardType = "calendar";

    readonly allowEdit = this.environment.featureFlags.editCardDescription;
    readonly notInvitedToMeetingText = this.environment.featureFlags.text.default.notInvitedToMeeting;
    readonly meetingStatuses = MeetingStatus;
    readonly gaEvents = GA_EVENTS;
    readonly meetingTimeFormat = environmentCommon.dateFormats.short;
    readonly CALENDAR_SCHEDULE_TASK_ACTION_ID = CalendarAction.SCHEDULE;
    readonly CALENDAR_RESCHEDULE_TASK_ACTION_ID = CalendarAction.RESCHEDULE;
    readonly quillStyles = environmentCommon.quillConfig.styling;

    state$: Observable<CalendarState>;
    card$: Observable<IThreadCard>;
    invitation$: Observable<IInvitation>;
    userId$: Observable<string>;
    thread$: Observable<IThread>;
    replies$: Observable<CardReply[]>;
    invitedToMeeting$: Observable<boolean>;
    attendeeParticipants$: Observable<IParticipant[]> = of([]);
    avatar$: Observable<IAvatarContent[]> = of([]);
    meetingName$: Observable<string>;
    canRescheduleMeeting$: Observable<boolean>;
    edit$ = new Subject<boolean>();
    meetingStatus$: Observable<string>;
    attendees$: Observable<IParticipant[]>;
    vcDetails$: Observable<IVCDetails>;
    canEditMeeting$: Observable<boolean>;
    canCancelMeeting$: Observable<boolean>;
    canEditOccurence$: Observable<boolean>;

    subscriptions: Subscription[] = [];
    role: Role;
    invitationId: string;
    start: Date;
    end: Date;
    appointmentConfirmed = false;
    invitationCancelled = false;
    errorMessage: string;
    organiser: IParticipant;
    participants: IParticipant[];
    showJoinCall$: Observable<boolean>;
    showEndCall$: Observable<boolean>;

    readonly roles = Role;
    readonly CardStatus = CardStatus;

    private threadId: string;
    private cardId: string;
    private modelBuilder = new VcStateBuilder();

    constructor(
        @Inject(THREAD_CARD_RESOURCES) protected cardResources: CardResources,
        @Inject(TASK_ACTION_LIBRARY) protected taskActions: ILibrary<TaskAction<ISlot>>,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        public loader: Loader,
        private router: Router,
        private cardService: ThreadCardService,
        private calendarService: CalendarService,
        private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private dialogService: DialogService,
        private participantsCache: ParticipantCache,
        protected taskActionService: TaskActionService,
        private videoChatService: VideoChatService,
        private permissionService: PermissionService,
        private threadsService: ThreadsService,
    ) {
        super(cardResources, taskActionService);
        this.meetingStatus$ = this.cardResources.state$.pipe(
            map((state) => this.calendarService.getMeetingStatus(state)),
            shareReplay(1),
        );
    }

    async ngOnInit(): Promise<void> {
        const { thread$, card$, threadId, cardId, events$, state$, role, replies$ } = this.cardResources;
        this.vcDetails$ = this.modelBuilder.getState();
        this.userId$ = this.authService.getUser().pipe(
            filter((user) => !!user),
            map((user) => user.id),
        );

        this.thread$ = thread$;
        this.card$ = card$;
        this.invitation$ = card$.pipe(
            map((cardData) => cardData.subjects?.find((subject) => subject.type === SubjectType.Calendar)?.id),
            filter((invitationId) => !!invitationId),
            switchMap((invitationId) => this.calendarService.getClientInvitation(invitationId)),
        );

        this.threadId = threadId;
        this.cardId = cardId;
        this.state$ = state$ as Observable<CalendarState>;
        this.role = role;
        this.replies$ = replies$;
        this.subscriptions.push(this.state$.subscribe((result) => this.setState(result)));
        this.subscriptions.push(events$.subscribe((event) => this.modelBuilder.addEvent(event)));
        this.modelBuilder.setThreadAndState(threadId, cardId);

        this.initializeInvitationId();
        this.triggerReschedule();

        this.canEditMeeting$ = combineLatest([
            this.userId$,
            this.card$,
            this.state$,
            this.permissionService.checkPermissions(this.role, "ThreadUpdateAll"),
            this.meetingStatus$,
        ]).pipe(
            switchMap(([userId, card, state, hasPermission, meetingStatus]) => {
                if (!this.allowEdit) {
                    return of(false);
                }

                if (state?.cancelled || meetingStatus === this.meetingStatuses.Ended) {
                    return of(false);
                }

                if (card.createdBy == userId || hasPermission) {
                    return of(true);
                }

                return of(false);
            }),
            shareReplay(1),
        );

        this.canEditOccurence$ = combineLatest([this.canEditMeeting$, this.state$]).pipe(
            switchMap(([canEditMeeting, state]) => {
                if (canEditMeeting && state?.instances?.length > 1) {
                    return of(true);
                } else {
                    return of(false);
                }
            }),
        );

        this.canCancelMeeting$ = combineLatest([
            this.canEditMeeting$,
            this.permissionService.checkPermissions(this.role, "DeleteCalendarCard"),
        ]).pipe(
            switchMap(([canEditMeeting, hasPermission]) => {
                if (canEditMeeting && hasPermission) {
                    return of(true);
                } else {
                    return of(false);
                }
            }),
        );

        this.vcDetails$
            .pipe(
                filter((details) => !!details.sessionId),
                take(1),
            )
            .subscribe(() => {
                this.triggerJoinMeeting();
            });

        const participant$ = combineLatest([this.state$, this.userId$]);

        this.invitedToMeeting$ = this.getInvitedToMeeting(participant$);
        this.canRescheduleMeeting$ = this.invitedToMeeting$.pipe(
            map((isInvited: boolean) => (this.role !== this.roles.Client || isInvited) && this.appointmentConfirmed),
        );

        this.attendeeParticipants$ = participant$.pipe(
            switchMap(([state]) => {
                const attendees = CalendarCardService.getAllAttendees(state);
                const participantIds = attendees.map((attendee) => attendee.id);
                return this.participantsCache.getParticipants(participantIds);
            }),
        );

        const createdBy = (await this.card$.pipe(take(1)).toPromise()).createdBy;

        this.organiser = await this.participantsCache.getParticipant(createdBy).toPromise();

        this.avatar$ = this.attendeeParticipants$.pipe(
            switchMap((participants) => this.participantsCache.getMultipleAvatars(participants)),
        );

        this.meetingName$ = this.state$.pipe(
            map((state) => {
                if (state?.details?.meetingName) {
                    return state.details.meetingName;
                } else {
                    return "Meeting";
                }
            }),
        );

        this.attendees$ = this.state$.pipe(
            switchMap((state) => {
                const attendees = CalendarCardService.getAllAttendees(state);
                const participantIds = attendees.map((attendee) => attendee.id);
                return this.participantsCache.getParticipants(participantIds);
            }),
        );

        const isThreadActive$ = this.thread$.pipe(
            map((thread) => this.threadsService.isThreadActive(thread)),
            shareReplay(1),
        );
        const joinVideoPermission$ = this.permissionService.checkPermissions(this.role, "JoinVcSession");
        const endVideoPermission$ = this.permissionService.checkPermissions(this.role, "EndSession");

        this.showJoinCall$ = combineLatest([isThreadActive$, joinVideoPermission$]).pipe(
            map(([isThreadActive, hasPermission]) => isThreadActive && hasPermission),
        );

        this.showEndCall$ = combineLatest([isThreadActive$, endVideoPermission$]).pipe(
            map(([isThreadActive, hasPermission]) => isThreadActive && hasPermission),
        );

        this.subscriptions.push(this.cardResources.navigateTo$.subscribe(() => this.openCalendarCardModal()));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => {
            sub.unsubscribe();
        });
    }

    async cancelMeeting(threadId: string, cardId: string): Promise<void> {
        this.loader.show();

        const currentUserId = await this.userId$.pipe(take(1)).toPromise();
        const currentUserInvited = await this.invitedToMeeting$.pipe(take(1)).toPromise();
        const firstAttendeeId = await this.attendees$
            .pipe(
                map((attendees) => attendees.slice(0, 1).pop().id),
                take(1),
            )
            .toPromise();

        const attendeeId = currentUserInvited ? currentUserId : firstAttendeeId;
        this.cancelInvitation(threadId, cardId, attendeeId);
    }

    //Terminate vc
    cancelInvitation(threadId: string, cardId: string, attendeeId: string): void {
        this.terminateSession()
            .pipe(
                switchMap(() =>
                    this.calendarService.cancelAppointment(threadId, cardId, this.invitationId, attendeeId),
                ),
                take(1),
            )
            .subscribe(() => {
                this.loader.hide();
            });
    }

    initializeInvitationId(): void {
        const { card$ } = this.cardResources;
        this.subscriptions.push(
            card$.subscribe((card) => {
                if (card.status !== CardStatus.Removed) {
                    this.invitationId = this.getInvitationId(card);
                }
            }),
        );
    }

    editMessage(): void {
        this.edit$.next(true);
    }

    async save(updatedMessage: string): Promise<void> {
        this.errorMessage = null;
        this.loader.show();
        try {
            await this.cardService
                .updateCardDescription(this.threadId, this.cardId, updatedMessage, CardStatus.Edited)
                .toPromise();

            this.loader.hide();
        } catch {
            this.loader.hide();
            this.errorMessage = "Sorry, something went wrong";
        }
    }

    async actionCallback(actionId: string, slot: ISlot): Promise<void> {
        if (
            actionId === this.CALENDAR_RESCHEDULE_TASK_ACTION_ID ||
            actionId === this.CALENDAR_SCHEDULE_TASK_ACTION_ID
        ) {
            if (slot) {
                this.appointmentConfirmed = true;
                this.start = null;
                this.end = null;
            }
        }

        if (actionId === VideoChatAction.JOIN_CALL) {
            await this.promptAndTerminateSession();
        }
    }

    async openInstanceModal(): Promise<void> {
        this.loader.show();

        const state = await this.state$.pipe(take(1)).toPromise();
        if (!state) {
            return null;
        }

        const thread = await this.thread$.pipe(take(1)).toPromise();

        this.loader.hide();

        const options = {
            disableClose: false,
            backdropClass: "modal-backdrop",
            panelClass: ["threads-sidebar", "mat-dialog-no-styling"],
            closeOnNavigation: true,
            maxWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%",
            data: { invitationId: this.invitationId, state, thread },
        };

        void this.dialogService.open<CalendarInstanceData>(CalendarInstanceModalComponent, options).toPromise();
    }

    async openMeetingRequestModal(thread: IThread, edit?: boolean): Promise<Subscription> {
        this.loader.show();

        const state = await this.state$.pipe(take(1)).toPromise();
        if (!state) {
            return null;
        }

        const invite = await this.calendarService.getClientInvitation(this.invitationId).toPromise(); //TODO: should be able to get all details from card state, no need to pull invite
        const calendarInvitees = [...invite.staff, ...invite.invitees];
        const participantIds = calendarInvitees.map((attendee) => attendee.id);
        const attendees = this.getAttendeesInThread(calendarInvitees, thread, participantIds);
        const invalidAttendees = await this.getAttendeesNotInThread(calendarInvitees, participantIds, thread);

        this.loader.hide();

        const detailsData: MeetingRequestModalData = {
            thread,
            edit,
            meetingData: {
                title: state.details.meetingName || state.details.title,
                meetingDescription: invite.message.description,
                numberOfOccurrences: invite.recurrence?.numberOfOccurrences,
                recurrenceType: invite.recurrence?.type,
                attendees,
                invalidAttendees,
                duration: invite.duration,
                organiser: invite.organizer.id,
            },
        };

        const options = {
            disableClose: false,
            backdropClass: "modal-backdrop",
            panelClass: ["threads-sidebar", "mat-dialog-no-styling"],
            closeOnNavigation: true,
            maxWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%",
            height: "100vh",
            data: detailsData,
        };

        return this.dialogService
            .open<ICalendarParticipant[]>(CalendarMeetingRequestComponent, options)
            .pipe(
                filter((postMeetingAttendees: ICalendarParticipant[]) => !!postMeetingAttendees),
                switchMap((postMeetingAttendees: ICalendarParticipant[]) => {
                    return this.updateAppointmentAttendees(
                        this.threadId,
                        this.cardId,
                        this.invitationId,
                        postMeetingAttendees,
                    );
                }),
            )
            .subscribe();
    }

    openFullscreen(join?: boolean): void {
        if (join) {
            this.action(VideoChatAction.JOIN_CALL);
        } else {
            this.action(VideoChatAction.START_CALL);
        }
    }

    promptAndTerminateSession(): Promise<ISlot> {
        return this.action(VideoChatAction.END_SESSION);
    }

    terminateSession(): Observable<void> {
        return this.vcDetails$.pipe(
            take(1),
            switchMap((detail) => {
                const sessionId = detail.sessionId;
                if (!sessionId) {
                    return of(null);
                }
                const threadId = this.threadId;
                const cardId = this.cardId;
                const terminate$ = this.videoChatService.terminateSession(sessionId, threadId, cardId);

                return this.loader.wrap(terminate$);
            }),
        );
    }

    private async updateAppointmentAttendees(
        threadId: string,
        cardId: string,
        invitationId: string,
        attendees: ICalendarParticipant[],
    ): Promise<void> {
        if (!invitationId || !attendees) {
            return;
        }

        this.loader.show();
        try {
            const staff = attendees.filter((attendee) => InternalRoles.includes(attendee.role));
            const invitees = attendees.filter((attendee) => attendee.role === Role.Client);

            await this.calendarService
                .updateAppointmentAttendees(threadId, cardId, invitationId, staff, invitees)
                .toPromise();
        } finally {
            this.loader.hide();
        }
    }

    private mapRequiredAttendees(
        attendeesNotInThread: ICalendarParticipant[],
        calendarInvitees: (IStaff | IInvitee)[],
    ): ICalendarParticipant[] {
        return attendeesNotInThread.map((participant) => {
            const invitee = calendarInvitees.find((calendarInvitee) => calendarInvitee.id === participant.id);
            return {
                ...participant,
                required: invitee.required,
            };
        });
    }

    private async getAttendeesNotInThread(
        calendarInvitees: (IStaff | IInvitee)[],
        participantIds: string[],
        thread: IThread,
    ): Promise<ICalendarParticipant[]> {
        const attendees = participantIds.filter((participantId) =>
            thread.participants.every((participant) => participant.id !== participantId),
        );

        const attendeesNotInThread = await this.participantsCache.getParticipants(attendees).toPromise();

        return this.mapRequiredAttendees(attendeesNotInThread, calendarInvitees);
    }

    private getAttendeesInThread(
        calendarInvitees: (IStaff | IInvitee)[],
        thread: IThread,
        participantIds: string[],
    ): ICalendarParticipant[] {
        const attendees = thread.participants.filter((participant) => participantIds.includes(participant.id));

        return this.mapRequiredAttendees(attendees, calendarInvitees);
    }

    private setState(state: Partial<CalendarState>): void {
        if (state) {
            const { instances, scheduled, cancelled } = state;
            const lastInstance = this.calendarService.findNextInstance(instances);

            if (lastInstance) {
                const { start, end } = lastInstance;
                const startDate = new Date(start);
                const endDate = new Date(end);

                if (!isNaN(startDate.getTime())) {
                    this.start = startDate;
                }

                if (!isNaN(endDate.getTime())) {
                    this.end = endDate;
                }
            }

            this.appointmentConfirmed = scheduled;
            this.invitationCancelled = cancelled;
        }
    }

    private getInvitationId(card: IThreadCard): string {
        const calendarSubjects = card.subjects.filter((cardSubject) => cardSubject.type === SubjectType.Calendar);
        if (calendarSubjects.length !== 1) {
            console.error("Did not find exactly 1 calendar subject", calendarSubjects);
            return null;
        }

        const subject = calendarSubjects.pop();
        return subject.id;
    }

    private getInvitedToMeeting(participant$: Observable<[CalendarState, string]>): Observable<boolean> {
        return participant$.pipe(
            map(([state, userId]) => {
                const attendees = CalendarCardService.getAllAttendees(state);
                return attendees.some((attendee) => attendee.id === userId);
            }),
            shareReplay(1),
        );
    }

    private triggerReschedule(): void {
        const { cardId } = this.activatedRoute.snapshot.firstChild.params || this.activatedRoute.snapshot.params;
        const { reschedule } = this.activatedRoute.snapshot.queryParams;
        if (cardId === this.cardId && reschedule && this.role === Role.Client) {
            this.action(this.CALENDAR_RESCHEDULE_TASK_ACTION_ID);
        }
    }

    private async triggerJoinMeeting(): Promise<void> {
        const { cardId } = this.activatedRoute.snapshot.firstChild.params || this.activatedRoute.snapshot.params;
        const { join } = this.activatedRoute.snapshot.queryParams;

        if (cardId === this.cardId && join === "true") {
            this.openFullscreen(true);
            await this.router.navigate([], { queryParams: { join: false } });
        }
    }

    private async openCalendarCardModal(): Promise<void> {
        await this.taskActionService.action(CalendarAction.SCHEDULE, this.cardResources);
    }
}
