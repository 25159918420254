import { Component, forwardRef, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription } from "rxjs";
import { FilterOption, ITimelineFilters } from "../../interfaces/timeline-filters";
import { IParticipant, Role, ThreadFilters, ThreadStatus } from "@visoryplatform/threads";
import { GA_EVENTS } from "../../../analytics";
import { AuthService } from "../../../findex-auth";
import { map, shareReplay, skip, switchMap, take } from "rxjs/operators";
import { ALL_OPTION } from "../../constants/option-constants";
import { ThreadFilterService } from "../../../threads-ui/services/thread-filter.service";
import { ParticipantCache } from "../../../threads-ui/services/participant-cache.service";
import { ThreadsService } from "../../../threads-ui/services/threads.service";
import { EnvironmentSpecificConfig } from "../../../environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";
import { ActivatedRoute } from "@angular/router";
import { Loader } from "../../../shared/services/loader";

type AccountFilter = { label: string; id: string };
type WorkflowLabelFilter = { label: string };
type AssigneesFilter = { assignees: string };
type ThreadTypeFilter = { type: string };

@Component({
    selector: "timelines-filters",
    templateUrl: "./timelines-filters.component.html",
    styleUrls: ["./timelines-filters.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TimelinesFiltersComponent),
            multi: true,
        },
    ],
})
export class TimelinesFiltersComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {
    @Input() accountId: string;
    @Input() includeAll?: boolean;

    statusOptions: FilterOption[];
    accounts$: Observable<FilterOption[]>;
    assignees$: Observable<FilterOption[]>;
    workflows$: Observable<FilterOption[]>;
    services$: Observable<FilterOption[]>;

    accountIdSubject = new BehaviorSubject<string>("");
    accountId$ = this.accountIdSubject.asObservable();

    includeAllSubject = new BehaviorSubject<boolean>(false);
    includeAll$ = this.includeAllSubject.asObservable();

    readonly role = Role;
    readonly gaEvents = GA_EVENTS;
    readonly statusesPrefixx = "statuses";
    readonly workflowsPrefix = "workflows";
    readonly servicesPrefix = "services";
    readonly assigneesPrefix = "assignees";
    readonly accountsPrefix = "accounts";

    searchQuerySource$ = new Subject<string>();
    searchQuery$ = this.searchQuerySource$.asObservable();
    filtersSubscription: Subscription;
    allOptionsSubscription: Subscription;

    form = new FormGroup({
        type: new FormControl<FilterOption>(null),
        status: new FormControl<FilterOption>(null),
        account: new FormControl<FilterOption>(null),
        search: new FormControl<string>(""),
        assignees: new FormControl<FilterOption>(null),
        workflow: new FormControl<FilterOption>(null),
    });

    globalRole$: Observable<Role>;

    onChange?: (value: unknown) => void;
    onTouch?: () => void;

    protected readonly ALL_OPTION = ALL_OPTION;

    constructor(
        private authService: AuthService,
        private threadFilterService: ThreadFilterService,
        private participantCache: ParticipantCache,
        private threadsService: ThreadsService,
        private route: ActivatedRoute,
        public loader: Loader,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        const { accountId, includeAll } = changes;

        if (accountId && accountId?.currentValue) {
            this.accountIdSubject.next(this.accountId);
        }

        if (includeAll) {
            this.includeAllSubject.next(this.includeAll ?? false);
        }
    }

    ngOnInit(): void {
        this.globalRole$ = this.authService.getValidUser().pipe(
            map((user) => user.globalRole),
            take(1),
            shareReplay(1),
        );

        this.statusOptions = this.getStatusOptions();

        this.accounts$ = this.accountId$.pipe(
            switchMap((accountId) => (accountId ? of([]) : this.getAccountsFilters())),
            shareReplay(1),
        );

        const threadFilters$ = this.getThreadSourceFilters();

        this.assignees$ = combineLatest([threadFilters$, this.includeAll$]).pipe(
            switchMap(([threadFilters, includeAll]) => this.getAssigneesFilters(threadFilters, includeAll)),
            shareReplay(1),
        );
        this.workflows$ = combineLatest([threadFilters$, this.includeAll$]).pipe(
            switchMap(([threadFilters, includeAll]) => this.getWorkflowsFilters(threadFilters, includeAll)),
            shareReplay(1),
        );
        this.services$ = combineLatest([threadFilters$, this.includeAll$]).pipe(
            switchMap(([threadFilters, includeAll]) => this.getServicesFilters(threadFilters, includeAll)),
            shareReplay(1),
        );

        const allOptions$ = [this.accounts$, this.assignees$, this.workflows$, this.services$];
        this.allOptionsSubscription = this.loader
            .wrap(combineLatest(allOptions$))
            .pipe(
                take(1),
                switchMap((filterOptions) => this.getFiltersValues(filterOptions)),
            )
            .subscribe((filterValues) => {
                this.form.setValue(filterValues);
            });

        this.initFormOnChanges();
    }

    addAllOption(options: FilterOption[]): FilterOption[] {
        return [ALL_OPTION, ...options];
    }

    ngOnDestroy(): void {
        this.filtersSubscription?.unsubscribe();
        this.allOptionsSubscription?.unsubscribe();
    }

    writeValue(value: ITimelineFilters): void {
        this.form.setValue(value, { emitEvent: false });
    }

    registerOnChange(fn: (value: ITimelineFilters) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    private initFormOnChanges(): void {
        this.filtersSubscription = this.form.valueChanges.pipe(skip(1)).subscribe((values) => {
            this.onChange(values);
        });
    }

    private getFiltersValues(filterOptions: FilterOption[][]): Observable<ITimelineFilters> {
        return this.accountId$.pipe(map((accountId) => this.getQueryParamFilters(filterOptions, accountId)));
    }

    private getQueryParamFilters(filterOptions: FilterOption[][], defaultAccountId: string): ITimelineFilters {
        const { status, type, account, assignees, workflow, search } = this.route.snapshot.queryParams;
        const [accountFilters, assigneeFilters, workflowFilters, typeFilters] = filterOptions;

        const statusFilterOption = this.findStatusFilterOption(status, this.statusOptions);
        const typeOption = this.findFilterOptionByKey(type, typeFilters);
        const accountOption = this.findFilterOptionByKey(defaultAccountId || account, accountFilters);
        const assigneesOption = this.findFilterOptionByKey(assignees, assigneeFilters);
        const workflowOption = this.findFilterOptionByKey(workflow, workflowFilters);

        return {
            status: statusFilterOption,
            type: typeOption,
            account: accountOption,
            assignees: assigneesOption,
            workflow: workflowOption,
            search: search || "",
        };
    }

    private findFilterOptionByKey(key: string | unknown, filterOptions: FilterOption[]): FilterOption {
        if (typeof key !== "string") {
            return ALL_OPTION;
        }

        const value = filterOptions.find((option) => option.key === key)?.value;
        return { key: key || ALL_OPTION.key, value: value || ALL_OPTION.value };
    }

    private findStatusFilterOption(key: string | unknown, statusOptions: Array<FilterOption>): FilterOption {
        if (typeof key !== "string") {
            return { key: ThreadStatus.active, value: ThreadStatus.active };
        }

        const value = statusOptions.find((option) => option.key === key)?.value;
        return { key: key || ThreadStatus.active, value: value || ThreadStatus.active };
    }

    private getAssigneesFilters(threadFilters: ThreadFilters, includeAll?: boolean): Observable<FilterOption[]> {
        return this.globalRole$.pipe(
            map((role) => this.threadFilterService.getAssigneeFilterSource(role)),
            switchMap((source) =>
                this.threadFilterService.getFilteredThreads<AssigneesFilter>(source, threadFilters, includeAll),
            ),
            switchMap((assignees) => this.getParticipantsOfAssignees(assignees)),
            map((participants) => participants.sort((a, b) => a?.profile?.name?.localeCompare(b?.profile?.name))),
            map((participants) => participants.map((participant) => this.mapParticipantToFilterOption(participant))),
            map((participants) => this.addAllOption(participants)),
        );
    }

    private getParticipantsOfAssignees(assignees: AssigneesFilter[]): Observable<IParticipant[]> {
        const uniqueAssigneeIds = new Set(assignees.map((assignee) => assignee.assignees));
        const assigneesIds = Array.from(uniqueAssigneeIds);
        return this.participantCache.getParticipants(assigneesIds);
    }

    private mapParticipantToFilterOption(participant): FilterOption {
        return { key: participant.id, value: participant.profile.name };
    }

    private getThreadSourceFilters(): Observable<ThreadFilters> {
        return this.accountId$.pipe(map((accountId) => (accountId ? { account: accountId } : {})));
    }

    private getAccountsFilters(): Observable<FilterOption[]> {
        return this.threadFilterService.getFilteredThreads<AccountFilter>("account").pipe(
            map((accounts) => accounts.sort((a, b) => a.label.localeCompare(b.label))),
            map((accounts) => accounts.map((account) => ({ key: account.id, value: account.label }))),
            map((accounts) => this.addAllOption(accounts)),
        );
    }

    private getWorkflowsFilters(threadFilters: ThreadFilters, includeAll?: boolean): Observable<FilterOption[]> {
        return this.threadFilterService
            .getFilteredThreads<WorkflowLabelFilter>("workflow", threadFilters, includeAll)
            .pipe(
                map((accounts) => accounts.sort((a, b) => a.label.localeCompare(b.label))),
                map((workflows) => workflows.map((workflow) => ({ key: workflow.label, value: workflow.label }))),
                map((workflows) => this.addAllOption(workflows)),
            );
    }

    private getServicesFilters(threadFilters: ThreadFilters, includeAll?: boolean): Observable<FilterOption[]> {
        const filteredTypes$ = this.threadFilterService.getFilteredThreads<ThreadTypeFilter>(
            "type",
            threadFilters,
            includeAll,
        );
        const threadTypes$ = this.threadsService.getThreadTypes();
        return combineLatest([filteredTypes$, threadTypes$]).pipe(
            map(([filteredTypes, threadTypes]) => this.mapThreadTypesToFilterOptions(filteredTypes, threadTypes)),
            map((workflows) => workflows.filter((workflow) => !!workflow.value)),
            map((workflows) => workflows.sort((a, b) => a.value.localeCompare(b.value))),
            map((workflows) => this.addAllOption(workflows)),
        );
    }

    private mapThreadTypesToFilterOptions(
        filteredTypes: ThreadTypeFilter[],
        threadTypes: Record<string, string>,
    ): FilterOption[] {
        return filteredTypes.map((type) => ({ key: type.type, value: threadTypes[type.type] }));
    }

    private getStatusOptions(): FilterOption[] {
        const threadStatusOptions = Object.entries(this.environment.featureFlags.threadListFilterStatus)
            .map(([key, value]) => ({ key, value }))
            .sort((a, b) => a.value.localeCompare(b.value));
        const allOption = { ...ALL_OPTION, value: "All statuses" };
        return [allOption, ...threadStatusOptions];
    }
}
