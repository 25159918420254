import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { IAccountListing, ITimeline } from "@visoryplatform/threads";
import { Observable, combineLatest } from "rxjs";
import { Loader } from "../../../shared/services/loader";
import { GA_EVENTS } from "../../../analytics/services/gtagAnalytics.service";
import { AccountsService } from "../../../threads-ui/services/accounts.service";
import { WindowListenersService } from "../../../shared/services/window-listeners.service";
import { EnvironmentSpecificConfig } from "../../../environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";
import { CustomerRequestService } from "../../services/customer-request.service";
import { filter, shareReplay, take, map } from "rxjs/operators";
import { LaunchDarklyFeatureFlags } from "../../../feature-flags/enums/LaunchDarklyFeatureFlags";
import { MenuService, MenuType } from "../../../shared/services/menu.service";
import { FeatureFlagService } from "../../../feature-flags";
import { CdkStepper } from "@angular/cdk/stepper";

type ICreateCustomerRequestModalForm = {
    account: FormControl<IAccountListing | null>;
    message: FormControl<string>;
};

@Component({
    selector: "create-customer-request-modal",
    templateUrl: "./create-customer-request-modal.component.html",
    styleUrls: ["./create-customer-request-modal.component.scss"],
})
export class CreateCustomerRequestModalComponent implements OnInit {
    @ViewChild("stepper") stepper: CdkStepper;

    readonly gaEvents = GA_EVENTS;
    readonly messageSizeQuotaInKB = 128;
    readonly FEATURE_FLAGS = LaunchDarklyFeatureFlags;
    loader = new Loader();
    isMobileView = false;
    createdThread: ITimeline;

    accounts$: Observable<IAccountListing[]>;
    messageButtonVisible$: Observable<boolean>;
    phoneButtonVisible$: Observable<boolean>;
    emailButtonVisible$: Observable<boolean>;
    customerNewRequestStepperVisible$: Observable<boolean>;

    form: FormGroup<ICreateCustomerRequestModalForm> = new FormGroup({
        account: new FormControl<IAccountListing | null>(null, [Validators.required]),
        message: new FormControl<string>("", [Validators.required]),
    });

    constructor(
        private accountService: AccountsService,
        private windowListenersService: WindowListenersService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private customerRequestService: CustomerRequestService,
        private menuService: MenuService,
        private featureFlagService: FeatureFlagService,
    ) {
        this.isMobileView = this.windowListenersService.isWindowSmaller(
            this.environment.featureFlags.windowWidthTabletBreakpoint,
        );
        this.messageButtonVisible$ = this.featureFlagService.getFlag(
            LaunchDarklyFeatureFlags.EnableCustomerNewRequestMessageButton,
        );
        this.phoneButtonVisible$ = this.featureFlagService.getFlag(
            LaunchDarklyFeatureFlags.EnableCustomerNewRequestPhoneButton,
        );
        this.emailButtonVisible$ = this.featureFlagService.getFlag(
            LaunchDarklyFeatureFlags.EnableCustomerNewRequestEmailButton,
        );
        this.customerNewRequestStepperVisible$ = combineLatest([
            this.messageButtonVisible$,
            this.phoneButtonVisible$,
            this.emailButtonVisible$,
        ]).pipe(
            map((res) => res.some(Boolean)),
            shareReplay(1),
        );
    }

    ngOnInit(): void {
        return;
    }

    configure(): void {
        this.accounts$ = this.loader.wrap(this.accountService.listAllAccounts()).pipe(shareReplay(1));

        const firstAccount$ = this.accounts$.pipe(
            filter((accounts) => accounts.length === 1),
            take(1),
        );

        firstAccount$.subscribe((accounts) => {
            const firstAccount = accounts[0];
            this.form.controls.account.setValue(firstAccount);
        });
    }

    close(): void {
        this.menuService.hide(MenuType.CustomerRequest);
    }

    goNextToCreate(): void {
        this.stepper.next();
        this.configure();
    }

    goBackToCreate(): void {
        this.stepper.previous();
        this.form.reset();
        this.configure();
    }

    async create(): Promise<void> {
        const { account, message } = this.form.value;

        this.loader
            .wrap(this.customerRequestService.createRequest(account.id, message))
            .pipe(take(1))
            .subscribe((thread) => {
                this.createdThread = thread;
                this.stepper.next();
            });
    }
}
