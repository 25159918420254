import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
    selector: "request-view-reply",
    templateUrl: "./request-view-reply.component.html",
    styleUrls: ["./request-view-reply.component.scss"],
})
export class RequestViewReplyComponent implements OnInit {
    @Input() cardDescription: FormControl<string>;

    constructor() {}
    ngOnInit() {}
}
