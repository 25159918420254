import { Inject, Injectable } from "@angular/core";
import { ENVIRONMENT } from "src/app/injection-token";
import { HttpClient } from "@angular/common/http";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { Observable } from "rxjs";
import { AccountStatement } from "@visoryplatform/copilot";

@Injectable()
export class CopilotService {
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig) {}

    getTransactions(threadId: string, fromDate: string, toDate: string): Observable<AccountStatement[]> {
        const url = `${this.environment.threadsEndpoints.base}/threads/${threadId}/copilot/statementlines`;
        return this.http.get<AccountStatement[]>(url, { params: { fromDate, toDate } });
    }
}
