export enum LaunchDarklyFeatureFlags {
    EnableInternalChat = "enable-internal-chat",
    EnableMemories = "enable-memories",
    EnableTransactionQueries = "enable-transaction-queries",
    EnableCustomerOnboarding = "enable-customer-onboarding",
    EnableDeplhiAlerts = "enable-delphi-alerts",
    EnableTransformersCard = "enable-transformer-card-type",
    EnableUniversalInsights = "enable-universal-insights",
    EnableWorkflowDashboardDueDate = "enable-workflow-dashboard-due-date",
    EnableWorkflowDashboardTasks = "enable-workflow-dashboard-tasks",
    EnableWorkflowListAndStepper = "enable-workflow-list-and-stepper",
    EnableConvertMessageToWorkflow = "enable-convert-message-to-workflow",
    EnableAIAssistant = "enable-ai-assistant",
    EnableDelphiBranding = "enable-delphi-branding",
    EnableWorkflowRelevantMemories = "enable-workflow-relevant-memories",
    EnableBulkChangeAssignees = "enable-bulk-change-assignees",
    EnableMonthEndWorkflowWithRoles = "enable-month-end-workflow-with-roles",
    EnableBillApproval = "enable-bills-approval",
    EnableForwardingMessageOrReply = "enable-forwarding-a-message-or-reply",
    EnableWorkflowResourceAllocation = "enable-workflow-resource-allocation",
    EnableCustomerNewRequest = "enable-customer-new-request",
    EnableGlobalCustomerNewRequest = "enable-global-customer-new-request",
    EnableNewAccountWorkflowsTab = "enable-new-account-workflows-tab",
    EnableQualityTeamRole = "enable-quality-team-role",
    EnableSuccessManagerRole = "enable-success-manager-role",
    EnablePartnerRole = "enable-partner-role",
    EnablePartnerFindexRole = "enable-partner-findex-role",
    EnableLeadRole = "enable-lead-role",
    EnableExpertRole = "enable-expert-role",
    EnableGlobalSearch = "enable-global-search",
    EnableScopingAndProposalWorkflow = "enable-scoping-and-proposal-workflow",
    EnableDelphiChecksWorkflows = "enable-delphi-checks-workflows",
    EnableCreateWorkflowModal = "enable-create-workflow-modal",
    EnableCustomerNewRequestMessageButton = "enable-customer-new-request-message-button",
    EnableCustomerNewRequestPhoneButton = "enable-customer-new-request-phone-button",
    EnableCustomerNewRequestEmailButton = "enable-customer-new-request-email-button",
    EnableCustomerRequestConfig = "enable-customer-request-config",
    EnableWorkTimeOnConfiguration = "enable-work-time-on-configuration",
    EnableWorkTimeOnWorkflowStepper = "enable-work-time-on-workflow-stepper",
    EnableAutomatedCheckViewResultsButton = "enable-automated-check-view-results-button",
    EnableNotificationFilters = "enable-notification-filters",
    EnableSupplierAutomatedReviewWorkflow = "enable-supplier-automated-review-workflow",
    EnableBillsApprovalCommentField = "enable-bills-approval-comment-field",
    EnableQuillMentions = "enable-quill-mentions",
    ConfigureQuillMentionsThreadType = "configure-quill-mention-thread-type",
    EnableMockWorkflows = "enable-mock-workflows",
}
