<div class="request-common-request-message-editor-field">
    <quill-editor-wrapper
        [message]="cardDescription"
        placeholder="Add a message, notes, and attachments that relate to your request."
    >
    </quill-editor-wrapper>
</div>
<div
    *ngIf="cardDescription.hasError('required') && cardDescription.touched"
    class="fx-form-validation"
>
    Note or message is required.
    <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
</div>
