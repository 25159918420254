<ng-container
    *ngIf="{
        card: card$ | async,
        state: state$ | async,
        thread: thread$ | async,
        form: form$ | async,
        userId: userId$ | async,
        replies: replies$ | async,
        role: role,
    } as context"
>
    <centered-modal-layout
        [loading]="!!loader.counter"
        [cancelButtonTitle]="buttonLabels.Close"
        [saveButtonTitle]="buttonLabels.SaveClose"
        [disableSave]="!plannedPaymentDate.valid || !range.valid"
        (save)="save(context.thread, context.card, context.state)"
        (close)="dialogRef.close()"
    >
        <div modal-header>
            <request-header
                title="Accounts payable report for review and approval"
                [isComplete]="context.state?.isCompleted"
                [completedStatusLabel]="requestStatuses.Completed"
                [accountName]="context.thread?.account?.label || ''"
            ></request-header>
        </div>

        <div modal-content>
            <div class="request-common-group">
                <request-view-reply [cardDescription]="cardDescription"></request-view-reply>
            </div>

            <div class="edit-bill-approval-controls">
                <div class="edit-bill-approval-controls-item">
                    <planned-payment-date [formControl]="plannedPaymentDate"></planned-payment-date>
                    <div
                        class="fx-form-validation brand-danger"
                        *ngIf="!plannedPaymentDate.valid && !plannedPaymentDate.value"
                    >
                        Planned payment date is required
                    </div>
                </div>
                <div class="edit-bill-approval-controls-item">
                    <due-date-range [formControl]="range"></due-date-range>
                    <div
                        class="fx-form-validation brand-danger"
                        *ngIf="!range.valid && !range.value"
                    >
                        Start date and end date is required
                    </div>
                </div>
            </div>

            <ng-container *ngIf="form$ | async as form">
                <div class="bill-approval-summary-title">
                    <div>
                        <h3 class="bill-approval-summary-title-heading">Bills awaiting payment</h3>
                        <div class="bill-approval-summary-title-desc">
                            Review bills for payment and approve those to be paid
                        </div>
                    </div>

                    <div class="bill-approval-summary-title-buttons">
                        <button
                            *ngIf="role | permissions : 'EditBillApprovalCard' : 'ThreadUpdateAll' | async"
                            [analyticsClick]="gaEvents.BILL_APPROVAL_EDIT_REFRESH"
                            class="fx-btn fx-btn--primary-outlined"
                            type="button"
                            (click)="refresh(form, context.thread.id)"
                        >
                            <i class="las la-sync"></i>
                            REFRESH
                        </button>
                    </div>
                </div>

                <loader-icon
                    *ngIf="!!tableLoader.counter"
                    color="light"
                    [static]="true"
                ></loader-icon>

                <ng-container *ngIf="invoices$ | async; let invoices">
                    <ng-container *ngIf="!tableLoader.counter">
                        <div [formGroup]="form">
                            <bill-approval-list
                                [form]="form"
                                *ngIf="invoices?.length"
                                [invoices]="invoices"
                                [state]="context.state"
                                [allowUpdate]="!context.card.isInternal"
                                [allowEdit]="true"
                                [allowEditComments]="false"
                                (updateTable)="updateTable($event)"
                            ></bill-approval-list>
                        </div>
                    </ng-container>

                    <empty-state
                        *ngIf="!invoices.length"
                        title="You're all up to date!"
                        htmlText="There are no bills due for the selected range"
                    >
                    </empty-state>
                </ng-container>
            </ng-container>
        </div>
    </centered-modal-layout>
</ng-container>
