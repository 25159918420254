import { IWorkflowVariation, VariationsControlContent } from "@visoryplatform/threads";
import { Pipe, PipeTransform } from "@angular/core";

import { WorkflowVariations } from "@visoryplatform/threads/dist/types/WorkflowVariations";

@Pipe({ name: "workflowVariationTokenToLabel" })
export class WorkflowVariationTokenToLabelPipe implements PipeTransform {
    transform(configTokenKey: string, listOfVariations: WorkflowVariations): string {
        const configTokensArray = Object.entries(listOfVariations);

        return configTokensArray.reduce(
            (configTokenLabel, [, variations]) =>
                this.getConfigTokenLabel(configTokenLabel, configTokenKey, variations),
            "",
        );
    }

    private getConfigTokenLabel(
        configTokenLabel: string,
        configTokenKey: string,
        variations: VariationsControlContent[],
    ): string {
        const configTokens = this.findVariationConfigToken(variations, configTokenKey);
        if (!configTokens.length) {
            return configTokenLabel;
        }

        const configToken = configTokens.find((token) => token.label);
        if (!configToken?.label) {
            console.warn(`No label found for config token: ${configToken}, ensure config token is setup`);
            return configTokenLabel;
        }

        return configToken.label;
    }

    private findVariationConfigToken(
        variations: VariationsControlContent[],
        configTokenKey: string,
    ): IWorkflowVariation[] {
        return variations
            .map((variation) => variation.tokens.find((token) => token.configTokens[configTokenKey]))
            .filter((token) => !!token);
    }
}
