import { Component, Injector, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { IThreadCard } from "@visoryplatform/threads/dist/interfaces/IThreadCard";
import { UnsavedModalDialogService } from "projects/portal-modules/src/lib/shared/services/unsaved-modal-dialog.service";
import { ThreadCardService } from "../../../../../portal-modules/src/lib/threads-ui/services/thread-card.service";
import { VaultRequestService } from "../../../services/vault-request.service";
import { retry, take } from "rxjs/operators";
import { RequestActionButtonLabel, RequestTitle, VaultCardType } from "../constants/request.constants";
import { PayrollReportRequest } from "src/environments/sigma-feature-flags";
import { ICreateRequestModalData } from "../interfaces/request.interfaces";
import { IRequestForm } from "../interfaces/IRequestForms";
import { IRequestAnalyticsTags } from "../interfaces/IRequestAnalyticsTags";
import { CreateRequestService } from "../create-rfi-request/create-request.service";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { DialogRef, DialogService } from "projects/portal-modules/src/lib/shared/services/dialog.service";

interface ICreateRequestCardResponse {
    card: IThreadCard;
    attachments: { vaultId: string; fileId: string };
}

@Component({
    selector: "app-create-payrun-request",
    templateUrl: "./create-payrun-request.component.html",
    styleUrls: ["./create-payrun-request.component.scss"],
})
export class CreatePayrunRequestComponent implements OnInit {
    readonly buttonLabel = RequestActionButtonLabel;

    accountName: string;
    analyticsTags: IRequestAnalyticsTags;
    form: FormGroup<IRequestForm>;
    loader = new Loader();
    modalTitle = RequestTitle;
    subtitle: string;

    modalData: ICreateRequestModalData;
    private dialogRef: DialogRef<string>;

    constructor(
        private cardService: ThreadCardService,
        private unsavedDialogService: UnsavedModalDialogService,
        private createRequestService: CreateRequestService,
        private vaultRequestService: VaultRequestService,
        private dialogService: DialogService,
        private injector: Injector,
    ) {}

    async ngOnInit(): Promise<void> {
        this.modalData = await this.dialogService.getData<ICreateRequestModalData>(this.injector).toPromise();
        this.dialogRef = await this.dialogService.getRef<string>(this.injector).toPromise();

        this.setLocalVariables(this.modalData);
        this.setCreatePayrunReportForm();
    }

    save(): void {
        const { title, cardDescription } = this.form.value;
        const cardType = this.modalData.type;

        this.createPayrunReportCard(cardDescription, title, cardType);
    }

    async close(): Promise<void> {
        if (!this.form.dirty) {
            this.dialogRef.close();
        } else {
            const confirmClose = await this.unsavedDialogService.confirmClose("payrun-create");
            if (confirmClose) {
                this.dialogRef.close();
            }
        }
    }

    private createPayrunReportCard(cardDescription: string, vaultTitle: string, cardType: string): void {
        const cardPayload = {
            cardDescription,
            vaultTitle,
            accountId: this.modalData.thread.accountId,
            payrunId: this.modalData.reportListItem.id.toString(),
            payrollType: this.modalData.provider,
            disableEmails: this.modalData.disableEmails,
        };

        this.loader
            .wrap(
                this.cardService
                    .createStackCard<any, ICreateRequestCardResponse>(this.modalData.thread.id, cardType, cardPayload)
                    .pipe(retry(1)),
            )
            .pipe(take(1))
            .subscribe((response) => this.dialogRef.close(response.card.id));
    }

    private getRequestTitle(data: ICreateRequestModalData): string {
        return data.reportListItem ? data.reportListItem.label : data.thread.title || "";
    }

    private setCreatePayrunReportForm(): void {
        this.form = new FormGroup<IRequestForm>({
            title: new FormControl(this.modalTitle.PayrolReport, [Validators.required]),
            cardDescription: new FormControl(PayrollReportRequest.Message, [Validators.required]),
        });
    }

    private setLocalVariables(modalData: ICreateRequestModalData): void {
        this.analyticsTags = this.vaultRequestService.getAnalyticsTags(VaultCardType.VaultPayrollApprovalRequest);
        this.subtitle = this.getRequestTitle(modalData);
        this.accountName = this.createRequestService.getRequestAccountName(modalData);
    }
}
