import { Frequency, RRule } from "rrule";
import { FrequencyLabel, IWorkflowVariation } from "@visoryplatform/threads";

export class SlaVariationsService {
    mapSlaToRRuleFrequency(workflowVariation?: IWorkflowVariation): string | null {
        if (!workflowVariation) {
            return null;
        }

        const [frequency, interval] = this.getFrequencyAndInterval(workflowVariation);

        const freq = this.getRruleFrequencyOption(frequency);

        const rruleString = new RRule({
            freq,
            interval,
        }).toString();

        return rruleString;
    }

    private getRruleFrequencyOption(frequency: string): Frequency {
        switch (frequency) {
            case FrequencyLabel.weekly:
                return Frequency.WEEKLY;
            case FrequencyLabel.monthly:
                return Frequency.MONTHLY;
            default:
                throw new Error(`Unsupported frequency: ${frequency}`);
        }
    }

    private getFrequencyAndInterval(workflowVariation: IWorkflowVariation): [string, number] {
        // Since we do not support a fortnightly frequency, we map it to weekly
        if (workflowVariation?.customFrequency === FrequencyLabel.fortnightly) {
            const fortnightlyInterval = 2;
            return [FrequencyLabel.weekly, fortnightlyInterval];
        }

        if (workflowVariation?.customFrequency === FrequencyLabel.quarterly) {
            const quarterlyInterval = 3;
            return [FrequencyLabel.monthly, quarterlyInterval];
        }

        const weeklyInterval = 1;
        return [workflowVariation.label, weeklyInterval];
    }
}
