import { Pipe, PipeTransform } from "@angular/core";
import { Duration } from "luxon";

// FIXME ED-8169 rescale() is part of Duration but requires updating @types/luxon to be recognised
interface DurationWithRescale extends Duration {
    rescale(): Duration;
}

@Pipe({ name: "duration" })
export class DurationPipe implements PipeTransform {
    transform(value: number, separator = ""): string {
        const duration = Duration.fromMillis(value);
        const scaledDuration = this.hasRescale(duration) ? duration.rescale() : duration;
        const readableDuration = scaledDuration.toHuman({ unitDisplay: "short" });

        return readableDuration.replace(/,/g, separator);
    }

    private hasRescale(duration: Duration): duration is DurationWithRescale {
        return "rescale" in duration && typeof duration.rescale === "function";
    }
}
