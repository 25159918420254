import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CdkStep, CdkStepper } from "@angular/cdk/stepper";

@Component({
    selector: "stepper-step",
    templateUrl: "stepper-step.component.html",
    styleUrls: ["stepper-step.component.scss"],
    providers: [{ provide: CdkStep, useExisting: StepperStepComponent }],
})
export class StepperStepComponent extends CdkStep {
    @Input() hideNavigationButtons = false;
    @Input() hideFooter = false;
    @Input() showBreadcrumbs = true;
    @Input() backStepLabel = "Back";
    @Input() nextStepLabel = "Next";
    @Input() disableNextOnClick = false;

    @Output() next = new EventEmitter<CdkStepper>();
}
