import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ENVIRONMENT } from "src/app/injection-token";
import { EnvironmentSpecificConfig } from "../../environment/environment.common";
import { HttpClient } from "@angular/common/http";
import { ITimeline } from "@visoryplatform/threads";

@Injectable({
    providedIn: "root",
})
export class CustomerRequestService {
    private environment = inject<EnvironmentSpecificConfig>(ENVIRONMENT);
    private http = inject(HttpClient);

    createRequest(accountId: string, message: string): Observable<ITimeline> {
        const { base } = this.environment.threadsEndpoints;
        const url = `${base}/accounts/${accountId}/request`;
        return this.http.post<ITimeline>(url, { message });
    }
}
