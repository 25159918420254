import { IThreadListing, ITimeline, WorkflowDueDateService } from "@visoryplatform/threads";
import { AssigneeExtensionHelpers, IWorkflow, SystemStepId } from "@visoryplatform/workflow-core";
import { ParticipantCache } from "./participant-cache.service";
import { Observable } from "rxjs";
import { IAvatarContent } from "@visoryplatform/fx-ui";
import { switchMap } from "rxjs/operators";
import { Injectable } from "@angular/core";

export type TableThreadListing = IThreadListing & {
    hasCancelTransition: boolean;
    updatedAt: string;
    currentStepDueDate: string;
    avatars$: Observable<IAvatarContent[]>;
};

@Injectable({ providedIn: "root" })
export class ThreadsEnrichmentService {
    constructor(private participantCacheService: ParticipantCache) {}

    enrichThreads(threadGroupListing: ITimeline[]): TableThreadListing[] {
        return threadGroupListing.map((threadListing) => {
            const updatedAt = threadListing.preview?.timestamp ?? threadListing.createdAt;
            const currentStepId = threadListing.workflow?.currentStepId;
            const currentStepDueDate = this.getCurrentStepDueDate(threadListing, currentStepId);
            const assignees = this.getAssigneesFromExtension(threadListing, currentStepId);
            const participants$ = this.participantCacheService.getParticipants(assignees);
            const avatars$ = participants$.pipe(
                switchMap((participants) => {
                    return this.participantCacheService.getMultipleAvatars(participants);
                }),
            );

            return {
                ...threadListing,
                hasCancelTransition: this.hasCancelTransition(threadListing?.workflow),
                updatedAt,
                currentStepDueDate,
                avatars$,
            };
        });
    }

    private getCurrentStepDueDate(threadListing: ITimeline, currentStepId: string): string | null {
        const step = threadListing?.workflow?.steps[currentStepId];

        if (!step) {
            return null;
        }

        const duration = WorkflowDueDateService.getExtensionDueDate(step)?.toISO();
        return duration && typeof duration === "string" ? duration : null;
    }

    private getAssigneesFromExtension(threadListing: ITimeline, currentStepId: string): string[] {
        const step = threadListing?.workflow?.steps[currentStepId];

        if (!step) {
            return [];
        }

        const assigneeData = AssigneeExtensionHelpers.getAssigneeData(step.extensions);
        return assigneeData?.assignees || [];
    }

    private hasCancelTransition(workflow?: IWorkflow): boolean {
        if (!workflow) {
            return false;
        }

        const currentStep = workflow?.steps[workflow.currentStepId];
        return currentStep?.actions?.some((action) => action.toStepId === SystemStepId.Cancelled);
    }
}
