<div
    class="thread-card-replies-button"
    *ngIf="!inlineCompose || (!showCompose && !disabled)"
    (click)="inlineCompose && (showCompose = true)"
    [analyticsClick]="cardType + '_cardreply'"
>
    Reply
</div>

<div
    class="thread-card-replies-compose"
    *ngIf="showCompose && inlineCompose"
>
    <div class="thread-card-replies-input">
        <quill-editor-wrapper
            [autoFocusOnInit]="true"
            (userInputEmitter)="onUserInput($event)"
            (error)="quillError = $event"
            [readOnly]="disabled"
            [message]="replyMessage"
            [inline]="true"
            [mentionableUsers]="mentionableUsers"
            [threadType]="threadType"
        ></quill-editor-wrapper>
    </div>

    <div class="thread-card-replies-compose-buttons">
        <reply-buttons
            [submitDisabled]="!replyMessage?.value || quillError || disabled"
            [submitTitle]="'Send'"
            (submitOutput)="reply(replyMessage)"
            (discardOutput)="replyMessage.reset(); showCompose = false"
        >
        </reply-buttons>
    </div>
</div>
