import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { environmentCommon } from "src/environments/environment";
import { IGlobalSearchMessage } from "@visoryplatform/threads";

const TEXT_HIGHT_COLOR = "#FCE5F1";

@Component({
    selector: "global-search-card",
    templateUrl: "./global-search-card.component.html",
    styleUrls: ["./global-search-card.component.scss"],
})
export class GlobalSearchCardComponent implements OnChanges {
    @Input() message: IGlobalSearchMessage;
    @Input() searchTerm: string;

    quillStyles = environmentCommon.quillConfig.styling;
    textContent = "";

    ngOnChanges(_changes: SimpleChanges): void {
        if (this.message?.message && this.searchTerm) {
            this.textContent = this.highlightText(this.message.message, this.searchTerm);
        } else {
            this.textContent = this.message.message;
        }
    }

    highlightText(text: string, searchTerm: string): string {
        const searchTerms = searchTerm.split(" ");
        const termsMatcher = searchTerms.join("|");
        const regex = new RegExp(`(?<!<[^>]*)(\\b(${termsMatcher})\\b)(?![^<]*>)`, "gi");
        const zeroWidthSpace = "&#x200B;";
        return text.replace(regex, `<span style='background-color: ${TEXT_HIGHT_COLOR};'>$1</span>${zeroWidthSpace}`);
    }
}
