<thread-card
    *ngIf="cardResources.card$ | async as card"
    [title]=""
    [thread]="cardResources.thread$ | async"
    [card]="card"
    [replies]="replies$ | async"
    [loading]="!!loader.counter"
    [inCardReplies]="false"
    [isInternal]="card.isInternal"
    (openRepliesInModal)="openViewModal(true)"
>
    <div
        *ngIf="cardResources.state$ | async as state"
        [ngClass]="{ 'bill-approval-panel--active': isThreadActive$ | async }"
        class="bill-approval-panel"
        (click)="openViewModal(false)"
    >
        <div class="bill-approval-panel-header">
            <h4 class="bill-approval-heading">Bill approval</h4>

            <fx-badge
                [label]="(isComplete$ | async) ? 'Completed' : 'Pending'"
                [type]="(isComplete$ | async) ? 'success' : 'default'"
                *ngIf="!card.isInternal"
            ></fx-badge>
        </div>

        <div class="bill-approval-panel-info">
            <div
                class="bill-approval-panel-progress"
                *ngIf="!card.isInternal"
            >
                <progress-bar [progress]="actionedPercentage$ | async"></progress-bar>
                <div class="bill-approval-panel-progress-actioned">
                    {{ actionAmmount$ | async }} / {{ actionTotal$ | async }} actioned
                </div>
            </div>

            <div
                class="bill-approval-panel-progress"
                *ngIf="card.isInternal"
            >
                <div class="bill-approval-panel-progress-actioned">{{ actionTotal$ | async }} items</div>
            </div>

            <div class="bill-approval-panel-info-icons">
                <i class="las la-comment-alt"></i>
                <span>{{ (replies$ | async)?.length || 0 }}</span>
            </div>
        </div>
    </div>

    <button
        *ngIf="hasPermissionToAction$ | async"
        class="bill-approval-button fx-btn fx-btn--primary"
        (click)="openViewModal(false)"
    >
        REVIEW
    </button>

    <div
        card-actions
        *ngIf="allowEdit"
    >
        <fx-context-menu
            *ngIf="(hasPermissionToDelete$ | async) || (hasPermissionToEdit$ | async)"
            #menu
            [gutter]="false"
        >
            <a
                *ngIf="hasPermissionToEdit$ | async"
                (click)="updateCard(cardResources.threadId, cardResources.cardId)"
                >Edit</a
            >
            <a
                *ngIf="hasPermissionToDelete$ | async"
                (click)="deleteCard(cardResources.threadId, cardResources.cardId)"
                >Delete</a
            >
        </fx-context-menu>
    </div>

    <div card-loader>
        <loader-icon color="light"></loader-icon>
    </div>
</thread-card>
