import { AppUser, AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MenuService, MenuType } from "projects/portal-modules/src/lib/shared/services/menu.service";
import { Notification, WebsocketData } from "@visoryplatform/notifications-core";
import { Observable, Subscription, combineLatest } from "rxjs";
import { filter, startWith, switchMap } from "rxjs/operators";

import { ActivityNotificationsService } from "projects/portal-modules/src/lib/notifications";
import { AnalyticsService } from "projects/portal-modules/src/lib/analytics/services/analytics.service";
import { EVENT_REPLY_TYPE } from "@visoryplatform/threads";
import { FormControl } from "@angular/forms";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics/services/gtagAnalytics.service";
import { INotification } from "src/app/interfaces/INotification";
import { Router } from "@angular/router";

export enum NotificationFilter {
    All = "all",
    Workflow = "workflow",
    InternalChat = "internalChat",
}

@Component({
    selector: "app-notifications-panel",
    templateUrl: "./notifications-panel.component.html",
    styleUrls: ["./notifications-panel.component.scss"],
})
export class NotificationsPanelComponent implements OnInit, OnDestroy {
    @Input() showMenu = false;
    @Input() unreadNotificationCount: number;
    notifications$: Observable<INotification[]>;
    showUnreadNotificationsOnly = new FormControl<boolean>(false);
    currentFilter = new FormControl<string>(NotificationFilter.All);
    user$: Observable<AppUser>;
    filterSubscription: Subscription;

    readonly gaEvents = GA_EVENTS;
    protected readonly notificationFilter = NotificationFilter;

    constructor(
        private router: Router,
        private menuService: MenuService,
        private analyticsService: AnalyticsService,
        private authService: AuthService,
        private activityNotifications: ActivityNotificationsService,
    ) {}

    ngOnInit(): void {
        this.user$ = this.authService.getUser();

        const currentFilter$ = this.currentFilter.valueChanges.pipe(startWith(this.currentFilter.value));
        const showUnreadNotificationsOnly$ = this.showUnreadNotificationsOnly.valueChanges.pipe(
            startWith(this.showUnreadNotificationsOnly.value),
        );

        this.filterSubscription = combineLatest([currentFilter$, showUnreadNotificationsOnly$]).subscribe(
            ([_currentFilter, _showUnreadNotificationsOnly]) => {
                this.setNotifications();
            },
        );
    }

    ngOnDestroy(): void {
        this.filterSubscription?.unsubscribe();
    }

    async getNextActivityPage(): Promise<void> {
        await this.activityNotifications.getNextPage();
    }

    async openNotification(notification: Notification<WebsocketData>): Promise<void> {
        const { topic, deliveryData } = notification;
        const { threadId, cardId, route, appNotificationUrl } = deliveryData.metadata;

        if (appNotificationUrl) {
            void this.router.navigate([appNotificationUrl]);
        } else if (threadId && cardId && topic === EVENT_REPLY_TYPE) {
            void this.router.navigate(["/timelines", threadId, "cards", cardId], {
                queryParams: { cardType: topic },
            });
        } else if (threadId && cardId && typeof cardId == "string") {
            await this.router.navigate(["/timelines", threadId], {
                skipLocationChange: true,
            });
            void this.router.navigate(["/timelines", threadId, "cards", cardId], {
                queryParams: { ignoreModalOpen: true },
            });
        } else if (threadId) {
            void this.router.navigate(["/timelines", threadId]);
        } else if (route && typeof route == "string") {
            void this.router.navigateByUrl(route);
        } else {
            console.warn("Notification has no routing information in metadata");
        }

        this.menuService.hide(MenuType.NotificationsMobile);
    }

    navigateToSettings(): void {
        void this.router.navigate(["/profile/notifications"]);
    }

    recordEvent(event: string): void {
        if (event === "read") {
            this.analyticsService.recordEvent("mouse-click", this.gaEvents.APP_MARKASREADNOTIFICATION);
        } else if (event === "delete") {
            this.analyticsService.recordEvent("mouse-click", this.gaEvents.APP_DELETENOTIFICATION);
        } else if (event === "unread") {
            this.analyticsService.recordEvent("mouse-click", this.gaEvents.APP_MARKASUNREADNOTIFICATION);
        }
    }

    setCurrentFilter(currentFilter: string): void {
        this.currentFilter.setValue(currentFilter);
        this.setNotifications();
    }

    markAllNotificationsAsRead(): void {
        this.recordEvent("markAllAsRead");
        this.setNotifications();
    }

    private setNotifications(): void {
        const currentGroupType = this.currentFilter.value !== NotificationFilter.All ? this.currentFilter.value : "";

        console.log("currentGroupType", currentGroupType);
        console.log("Form control val", this.showUnreadNotificationsOnly.value);

        this.notifications$ = this.user$.pipe(
            filter((user) => !!user),
            switchMap(() =>
                this.activityNotifications.getActivity(this.showUnreadNotificationsOnly.value, currentGroupType),
            ),
        );
    }
}
