import { CdkStepper } from "@angular/cdk/stepper";
import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "stepper-footer",
    templateUrl: "./stepper-footer.component.html",
    styleUrls: ["./stepper-footer.component.scss"],
})
export class StepperFooterComponent {
    @Input() endStepLabel?: string;
    @Input() selectedIndex: number;
    @Input() totalSteps: number;
    @Input() disabled?: boolean;
    @Input() disabledNext?: boolean;
    @Input() hideNavigationButtons = false;
    @Input() backStepLabel = "Back";
    @Input() nextStepLabel = "Next";

    @Output() completed = new EventEmitter<CdkStepper>();
    @Output() cancel = new EventEmitter<CdkStepper>();
    @Output() clickNext = new EventEmitter<CdkStepper>();

    constructor(public stepper: CdkStepper) {}

    handleNext(selectedIndex: number, totalSteps: number): void {
        this.clickNext.emit(this.stepper);

        if (selectedIndex === totalSteps - 1) {
            this.completed.emit(this.stepper);
        }
    }
}
